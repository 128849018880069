import { lazy } from "react";
import { Route } from "react-router-dom";
import AdminManageWrapper from "ui/pages/AdminManage/AdminManage.wrapper";
import { SyndicationManagementPartnerCreateWrapper } from "ui/pages/SyndicationManagement";
import { FeatureFlagId } from "domain/featureFlag";
import { config } from "config";
import { RestrictedByGroupOutlet } from "ui/Router/guards/RestrictedByGroupOutlet";
import { RestrictedByFeatureFlagOutlet } from "ui/Router/guards/RestrictedByFeatureFlagOutlet";

const AdminManageUsers = lazy(
  () => import("ui/pages/AdminManage/AdminManageUsers")
);
const AdminManageDeals = lazy(
  () => import("ui/pages/AdminManage/AdminManageDeals")
);
const AdminManageProjects = lazy(
  () => import("ui/pages/AdminManage/AdminManageProjects")
);
const AdminManageDevActions = lazy(
  () => import("ui/pages/AdminManage/AdminDevActions")
);
const SyndicationManagementInviteList = lazy(
  () => import("ui/pages/SyndicationManagement/SyndicationManagementInviteList")
);
const SyndicationManagementInviteConversions = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementInviteConversions"
    )
);
const SyndicationManagementCreateInvite = lazy(
  () =>
    import("ui/pages/SyndicationManagement/SyndicationManagementCreateInvite")
);
const SyndicationManagementPartnerList = lazy(
  () =>
    import("ui/pages/SyndicationManagement/SyndicationManagementPartnerList")
);
const SyndicationManagementPartnerDetailsCreate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerDetailsCreate"
    )
);
const SyndicationManagementPartnerDetailsUpdate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerDetailsCreate/SyndicationManagementPartnerDetailsCreate.update"
    )
);
const SyndicationManagementPartnerLegalUpdate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerLegalUpdate"
    )
);
const SyndicationManagementPartnerBrandingUpdate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerBrandingUpdate"
    )
);

const AdminManageFlags = lazy(
  () => import("ui/pages/AdminManage/AdminManageFlags")
);
const AdminManageUser = lazy(
  () => import("ui/pages/AdminManage/AdminManageUser")
);
const AdminManageSyndicationUsers = lazy(
  () => import("ui/pages/AdminManage/AdminManageSyndicationUsers")
);
const AdminManageSyndicationCampaigns = lazy(
  () => import("ui/pages/AdminManage/AdminManageSyndicationCampaigns")
);

const SyndicationManagementLoginPreview = lazy(
  () =>
    import("ui/pages/SyndicationManagement/SyndicationManagementLoginPreview")
);

export const adminManagementRoutes = [
  <Route
    key="adminManagementRoutes"
    path={"manage/"}
    element={<RestrictedByGroupOutlet admin syndicationAdmin />}
  >
    <Route element={<AdminManageWrapper />}>
      <Route path="users" element={<AdminManageUsers />} />
      <Route
        element={
          <RestrictedByFeatureFlagOutlet featureFlag={FeatureFlagId.Deals} />
        }
      >
        <Route path="deals" element={<AdminManageDeals />} />
      </Route>
      <Route
        element={
          config.env === "prod" ? (
            <RestrictedByGroupOutlet developer />
          ) : (
            <RestrictedByGroupOutlet admin />
          )
        }
      >
        <Route
          element={
            <RestrictedByFeatureFlagOutlet
              featureFlag={FeatureFlagId.Projects}
            />
          }
        >
          <Route path="projects" element={<AdminManageProjects />} />
        </Route>
      </Route>
      <Route path="dev-actions" element={<AdminManageDevActions />} />
      <Route path="flags" element={<AdminManageFlags />} />
      <Route
        path="syndication-users"
        element={<AdminManageSyndicationUsers />}
      />
      <Route
        path="syndication-campaigns"
        element={<AdminManageSyndicationCampaigns />}
      />
      <Route path="invites" element={<SyndicationManagementInviteList />} />
      <Route path="partners" element={<SyndicationManagementPartnerList />} />
    </Route>

    <Route path="partner">
      <Route
        path="create"
        element={<SyndicationManagementPartnerCreateWrapper />}
      >
        <Route index element={<SyndicationManagementPartnerDetailsCreate />} />
      </Route>

      <Route path=":partnerId">
        <Route path="preview" element={<SyndicationManagementLoginPreview />} />
        <Route
          path="update"
          element={<SyndicationManagementPartnerCreateWrapper />}
        >
          <Route
            index
            element={<SyndicationManagementPartnerDetailsUpdate />}
          />
          <Route
            path="legal"
            element={<SyndicationManagementPartnerLegalUpdate />}
          />
          <Route
            path="branding"
            element={<SyndicationManagementPartnerBrandingUpdate />}
          />
        </Route>
      </Route>
    </Route>

    <Route path="invite/:inviteCode">
      <Route
        path="conversions"
        element={<SyndicationManagementInviteConversions />}
      />
    </Route>

    <Route path="invite">
      <Route path="create" element={<SyndicationManagementCreateInvite />} />
      <Route
        path="edit/:inviteCode"
        element={<SyndicationManagementCreateInvite />}
      />
    </Route>

    <Route path="user/:id" element={<AdminManageUser />} />
  </Route>,
];

import { config } from "config";
import { PlatformProjectName } from "domain/platform";
import { FC, ReactNode } from "react";

interface PlatformSpecificElementElementProps {
  platform: PlatformProjectName;
  children: ReactNode;
}

export const PlatformSpecificElement: FC<
  PlatformSpecificElementElementProps
> = ({ platform, children }) => {
  return config.projectName === platform ? <>{children}</> : null;
};

import { lazy } from "react";
import { Route } from "react-router-dom";
import { RestrictedByGroupOutlet } from "ui/Router/guards/RestrictedByGroupOutlet";
import { CampaignsWrapper } from "ui/pages/Campaigns/CampaignsWrapper";

const Campaigns = lazy(() => import("ui/pages/Campaigns/Campaigns"));
const CampaignsContactLists = lazy(
  () => import("ui/pages/CampaignsContactLists")
);
const CampaignCreate = lazy(() => import("ui/pages/CampaignCreate"));
const CampaignReview = lazy(() => import("ui/pages/CampaignReview"));
const CampaignEdit = lazy(() => import("ui/pages/CampaignEdit"));
const CampaignsContactList = lazy(
  () => import("ui/pages/CampaignsContactList")
);

export const campaignRoutes = [
  <Route key="campaignRoutes" path="campaign">
    <Route element={<RestrictedByGroupOutlet admin syndicationAdmin />}>
      <Route element={<CampaignsWrapper />}>
        <Route path="regular" element={<Campaigns />} />
        <Route path="contact-lists" element={<CampaignsContactLists />} />
      </Route>
      <Route path="create" element={<CampaignCreate />} />
      <Route path="contact-list">
        <Route path=":id" element={<CampaignsContactList />} />
      </Route>
    </Route>
    <Route path=":campaignId">
      <Route element={<RestrictedByGroupOutlet admin syndicationAdmin />}>
        <Route path="edit">
          <Route index element={<CampaignEdit />} />
          <Route path="summary" element={<CampaignReview />} />
        </Route>
      </Route>
    </Route>
  </Route>,
];

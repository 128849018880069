import * as React from "react";
import type { SVGProps } from "react";
const SvgWealth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#CCB502"
      d="m5.88 19.368 1.257-1.256c1.305-1.305 1.957-1.957 2.737-2.12a2.7 2.7 0 0 1 1.085 0c.78.163 1.432.815 2.737 2.12.195.195.293.293.41.317a.4.4 0 0 0 .163 0c.117-.024.215-.122.41-.317l3.718-3.718-.115-.114c-.968-.969-1.453-1.453-1.282-1.865.17-.411.855-.411 2.225-.411h.781c1.257 0 1.886 0 2.276.39s.39 1.02.39 2.276v.781c0 1.37 0 2.055-.41 2.226-.412.17-.897-.314-1.865-1.283l-.115-.114-2.815 2.815c-1.305 1.305-1.957 1.957-2.737 2.12a2.7 2.7 0 0 1-1.085 0c-.78-.163-1.432-.815-2.737-2.12-.195-.195-.293-.293-.41-.317a.4.4 0 0 0-.163 0c-.117.024-.215.122-.41.317l-2.808 2.807a10.66 10.66 0 0 0 8.89 4.768c5.89 0 10.666-4.775 10.666-10.666S21.897 5.337 16.006 5.337c-5.89 0-10.666 4.776-10.666 10.667 0 1.175.19 2.306.54 3.364m-2.05 2.077-.013-.013.005-.005a13.3 13.3 0 0 1-1.15-5.423c0-7.364 5.97-13.334 13.334-13.334S29.34 8.64 29.34 16.004s-5.97 13.333-13.334 13.333c-5.425 0-10.094-3.24-12.176-7.892"
    />
  </svg>
);
export default SvgWealth;

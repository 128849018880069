import {
  AssistantOutlined,
  ExtensionOutlined,
  ManageAccountsOutlined,
  ManageHistory,
  SvgIconComponent,
} from "@mui/icons-material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactComponent as CogIcon } from "assets/svg/icon-cog.svg";
import { ReactComponent as SignOutIcon } from "assets/svg/icon-sign-out.svg";
import { ReactComponent as UserIcon } from "assets/svg/icon-user.svg";
import clsx from "clsx";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { signOut } from "redux/Auth/actions";
import * as paths from "ui/Router/paths";
import {
  useGroupValidation,
  useSyndicationLayout,
  useUserProfile,
} from "ui/hooks";
import { useCampaignQuota } from "ui/hooks/Deal/useDealCommunication";
import useOnClickOutside from "ui/hooks/useOnClickOutside";
import { useTypedSelector } from "ui/hooks/useTypeSelector";
import { NAV_PROFILE_BUTTON_ID } from "../AuthorizedBlock";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";
import { isBullpen } from "application/platformConfiguration";

interface UserMenuProps {
  closeMenu: () => void;
}

interface MenuListItem {
  label: string;
  to: string;
  Icon: React.VFC<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
  adminOnly?: boolean;
  developerOnly?: boolean;
  syndicationAdminAccess?: boolean;
  syndicationOnly?: boolean;
}

const UserMenu: React.FC<UserMenuProps> = ({ closeMenu }) => {
  const navigate = useNavigate();
  const ref = React.useRef(null);
  const {
    isFundManager,
    isSyndicationAdmin,
    hasAdminLevel,
    isAdmin,
    isDeveloper,
  } = useGroupValidation();
  const layout = useSyndicationLayout();
  const {
    user: { attributes },
  } = useTypedSelector((state) => state.auth);

  const { data } = useCampaignQuota(undefined, { isPaused: () => !isAdmin });
  const { isDealsOn } = useFeatureFlag();
  const { name, family_name, email } = attributes;

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const profileClick = Boolean(
      target.id === NAV_PROFILE_BUTTON_ID ||
        target.closest(`#${NAV_PROFILE_BUTTON_ID}`)
    );
    if (!profileClick) {
      closeMenu();
    }
  };

  useOnClickOutside(ref, handleClickOutside);

  const menuList: MenuListItem[] = useMemo(() => {
    const menuResult = [
      {
        label: "My profile",
        to: paths.userProfile,
        Icon: () => (
          <UserIcon width={20} height={20} className="fill-gray-400" />
        ),
      },
      {
        label: "Admin management",
        to: !layout.isSyndicate
          ? paths.manageUsersList
          : paths.manageSyndicationUsers,
        Icon: () => (
          <div className="text-gray-400">
            <ManageAccountsOutlined style={{ width: 20 }} />
          </div>
        ),
        adminOnly: true,
        syndicationAdminAccess: true,
      },
      {
        label: "Platform management",
        to: layout.isSyndicate
          ? generatePath(paths.managePartnerUpdate, {
              partnerId: layout.mainInfo?.id,
            })
          : "",
        Icon: () => (
          <div className="text-gray-400">
            <ExtensionOutlined style={{ width: 20 }} />
          </div>
        ),
        adminOnly: true,
        syndicationAdminAccess: true,
        syndicationOnly: true,
      },
      {
        label: "AI Management",
        to: isBullpen()
          ? paths.assistantConversations
          : paths.assistantTemplates,
        Icon: () => (
          <div className="text-gray-400">
            <AssistantOutlined style={{ width: 20 }} />
          </div>
        ),
        developerOnly: true,
      },
      ...(isBullpen()
        ? []
        : [
            {
              label: "Audit Activities",
              to: paths.auditActivities,
              Icon: () => (
                <div className="text-gray-400">
                  <ManageHistory style={{ width: 20 }} />
                </div>
              ),
              adminOnly: true,
            },
          ]),
      {
        label: "Preferences",
        to: paths.preferencesPath,
        Icon: CogIcon,
      },
    ];

    if (
      ((layout.isSyndicate && data?.isCampaignEnabled) || isAdmin) &&
      !isBullpen()
    ) {
      menuResult.splice(2, 0, {
        label: "Campaign management",
        to: paths.campaigns,
        Icon: () => (
          <div className="text-gray-400">
            <EmailOutlinedIcon style={{ width: 20 }} />
          </div>
        ),
        adminOnly: true,
        syndicationAdminAccess: true,
      });
    }

    return menuResult;
  }, [layout, data, isAdmin]);

  return (
    <div ref={ref} className="px-4 py-8 sm:max-w-[256px] sm:w-[256px] h-full">
      <Stack spacing={2.5} className="pb-5">
        <div className="leading-[0]">
          <Tooltip
            placement="bottom-end"
            title={<>{`${name} ${family_name ?? ""}`}</>}
          >
            <Typography
              className="block font-bold text-ellipsis overflow-hidden"
              variant="body11"
            >{`${name} ${family_name ?? ""}`}</Typography>
          </Tooltip>
          <Tooltip placement="bottom-end" title={<>{email}</>}>
            <Typography className="block font-bold truncate" variant="caption">
              {email}
            </Typography>
          </Tooltip>
        </div>
        {(isFundManager || hasAdminLevel) && isDealsOn && !isBullpen() && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              navigate(paths.dealCreateAI);
            }}
          >
            Create New Deal
          </Button>
        )}
      </Stack>
      <Stack spacing={1.25} divider={<Divider />}>
        {menuList.map((item, index) => {
          if (item.syndicationAdminAccess && !isSyndicationAdmin) {
            return null;
          }

          const allowAsSyndicationAdmin =
            item.syndicationAdminAccess && isSyndicationAdmin;

          if (item.developerOnly && !isDeveloper) {
            return null;
          }

          if (item.adminOnly && !(isAdmin || allowAsSyndicationAdmin)) {
            return null;
          }

          if (item.syndicationOnly && !layout.isSyndicate) {
            return null;
          }

          return <UserMenuItem key={index} {...item} />;
        })}
        <SignOutItem />
      </Stack>
    </div>
  );
};

interface UserMenuItemProps {
  Icon?: React.VFC;
  label: string;
  to?: string;
  disabled?: boolean;
}

const UserMenuItem: React.FC<UserMenuItemProps> = ({
  Icon,
  label,
  to,
  disabled,
}) => {
  const navigate = useNavigate();
  const { data } = useUserProfile();
  const customUsername = data?.customUsername;

  const onClick = () => {
    if (!disabled) {
      if (to) {
        navigate(
          `/${generatePath(to, { id: customUsername, customUsername })}`
        );
      }
    }
  };

  return (
    <div
      className={clsx("cursor-pointer flex items-center", {
        "cursor-progress": disabled,
      })}
      onClick={onClick}
    >
      {Icon && (
        <span className="leading-[0] pr-3">
          <Icon />
        </span>
      )}
      <Typography variant="button2" className="text-gray-400">
        {label}
      </Typography>
    </div>
  );
};

const SignOutItem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const onClick = async () => {
    setLoading(true);
    dispatch(signOut());
    navigate("/");
  };

  return (
    <div onClick={onClick}>
      <UserMenuItem label="Sign Out" Icon={SignOutIcon} disabled={loading} />
    </div>
  );
};

export default UserMenu;

import { FC, HTMLProps } from "react";
import styles from "./RadioButton.styles.module.scss";
import { useTheme } from "@mui/material";
import { ReactComponent as Checkmark } from "assets/svg/checkmark.svg";

export const RadioButton: FC<HTMLProps<HTMLInputElement>> = ({
  className,
  ...props
}) => {
  const theme = useTheme();

  return (
    <div className={className}>
      <input {...props} className={styles.Radio} type="radio" />
      <label
        htmlFor={props.id}
        className={styles.RadioLabel}
        style={{
          color: theme.palette.getContrastText(theme.palette.primary.main),
        }}
      >
        <Checkmark />
      </label>
    </div>
  );
};

/* eslint-disable react/prop-types */
import { CheckRounded, Close, ErrorOutline } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useCallback, useMemo } from "react";

interface VariantData {
  defaultTitle?: string;
  icon: React.ReactNode;
  color: string;
}

interface ContentProps extends CustomContentProps {
  title?: string;
}

const NotificationSnackbar = forwardRef<HTMLDivElement, ContentProps>(
  ({ id, variant, message, title }, ref): JSX.Element => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const { color, icon, defaultTitle } = useMemo<VariantData>(() => {
      switch (variant) {
        case "success":
          return {
            color: "bg-primary-300",
            icon: <CheckRounded />,
          };
        case "info":
          return {
            color: "bg-blue-400",
            icon: <ErrorOutline />,
            defaultTitle: "Information",
          };
        case "warning":
          return {
            color: "bg-yellow-400",
            icon: <ErrorOutline />,
            defaultTitle: "Warning",
          };
        case "error":
          return {
            color: "bg-error",
            icon: <ErrorOutline />,
            defaultTitle: "Something went wrong",
          };
        default:
          return { color: "bg-gray-400", icon: <ErrorOutline /> };
      }
    }, [variant]);

    const validTitle = useMemo(
      () => title || defaultTitle,
      [title, defaultTitle]
    );

    return (
      <SnackbarContent
        className="flex !flex-nowrap justify-between bg-white rounded-full w-[370px] max-w-full min-h-24 items-center p-4 border border-solid border-gray-150 shadow-depth-3"
        role="alert"
        ref={ref}
      >
        <div className="flex items-center">
          <div>
            <div
              className={clsx(
                "h-12 w-12 rounded-full flex items-center justify-center text-white",
                color
              )}
            >
              {icon}
            </div>
          </div>
          <div className="ml-3">
            {validTitle && (
              <div className="mb-1">
                <Typography
                  variant="caption"
                  className="text-dark-text !font-bold"
                >
                  {validTitle}
                </Typography>
              </div>
            )}
            <div>
              <Typography variant="caption2" className="text-gray-400">
                {message}
              </Typography>
            </div>
          </div>
        </div>
        <div className="pl-1">
          <IconButton
            onClick={handleDismiss}
            className="text-sm cursor-pointer text-gray-400 hover:text-gray-500 transition-colors ease-in-out"
          >
            <Close />
          </IconButton>
        </div>
      </SnackbarContent>
    );
  }
);

NotificationSnackbar.displayName = "NotificationSnackbar";

export { NotificationSnackbar };

import { lazy } from "react";
import { Route } from "react-router-dom";
import * as paths from "ui/Router/paths";
import { RestrictedByGroupOutlet } from "ui/Router/guards/RestrictedByGroupOutlet";
import AssistantManagementWrapper from "ui/pages/AssistantManagement/AssistantManagement.wrapper";

const AssistantManagementConversations = lazy(
  () => import("ui/pages/AssistantManagement/AssistantManagement.conversations")
);

const AssistantManagementEmailConversations = lazy(
  () =>
    import(
      "ui/pages/AssistantManagement/AssistantManagement.email-conversations"
    )
);

const AssistantManagementEmailRecipients = lazy(
  () =>
    import("ui/pages/AssistantManagement/AssistantManagement.email-recipients")
);

const AssistantManagementSettings = lazy(
  () => import("ui/pages/AssistantManagement/AssistantManagementSettings")
);

export const bullpenAssistantManagementRoutes = [
  <Route
    key="assistantManagementRoutes"
    element={<RestrictedByGroupOutlet developer />}
  >
    <Route
      path={paths.assistantManagement}
      element={<AssistantManagementWrapper />}
    >
      <Route path="settings" element={<AssistantManagementSettings />} />
      <Route
        path="conversations"
        element={<AssistantManagementConversations />}
      />
      <Route path="email-conversations">
        <Route
          path="recipients"
          element={<AssistantManagementEmailRecipients />}
        />
        <Route index element={<AssistantManagementEmailConversations />} />
      </Route>
    </Route>
  </Route>,
];

import projectReportService from "services/projectReport.service";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { IProjectReport } from "types/project.service";

export const getProjectReportSWRKey = (projectId: string, reportId: string) =>
  `/Project/${projectId}/report-builder/reports/${reportId}`;

export const useProjectReport = (
  projectId: string,
  reportId?: string,
  config?: SWRConfiguration<IProjectReport>
): SWRResponse<IProjectReport> => {
  return useSWR(
    reportId && getProjectReportSWRKey(projectId, reportId),
    () => projectReportService.getReport(projectId, reportId!),
    config
  );
};

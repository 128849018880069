import { excludeItemsWhenBullpen } from "bullpen/utils/excludeItemsWhenBullpen";
import { uniqBy } from "lodash";
import {
  DataRoomRoles,
  UsersWithRoles,
} from "ui/pages/DealDataRoom/DealDataRoom.types";

export const removeRolesDuplicates = (
  users: UsersWithRoles
): UsersWithRoles => {
  return users?.map(({ roles, ...rest }) => ({
    roles: uniqBy(roles, "roleId"),
    ...rest,
  }));
};

export const filterRoles = (data: DataRoomRoles, exclude: string[]) =>
  data.filter(({ roleId }) => excludeItemsWhenBullpen<string>(exclude)(roleId));

import { Add, DeleteForever, PodcastsOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  IconButton,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isUndefined } from "lodash";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import mediaService from "services/media.service";
import useSWR from "swr";
import { IMediaItemPreview } from "types/media.service";
import { Loading } from "ui/components";
import { EmptyContentScreen } from "ui/components/EmptyContentScreen";
import { LinkTabs } from "ui/components/LinkTabs";
import PageHeaderAction from "ui/components/PageHeaderAction/PageHeaderAction";
import { SearchInput } from "ui/components/SearchInput";
import { useErrorHandler, useGroupValidation } from "ui/hooks";
import * as paths from "ui/Router/paths";
import { PodcastCard } from "../../components/PodcastCard";
import ModalComponent from "../CommonLayout/Modals/ModalComponent";
import SEOHelmet from "../../components/SEOHelmet/SEOHelmet";

interface FormInputs {
  search: string;
}

interface TabItemDef {
  label: string;
  to: string;
}

const items: TabItemDef[] = [
  {
    label: "Emergent Investments",
    to: paths.podcasts,
  },
  {
    label: "All deals",
    to: paths.dealPodcasts,
  },
];

export enum PodcastListType {
  Emergent = "emergent",
  Deals = "deals",
}

interface PodcastsProps {
  type: PodcastListType;
}

const Podcasts: FC<PodcastsProps> = ({ type }) => {
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();
  const theme = useTheme();
  const breakpointMdOrMore = useMediaQuery(theme.breakpoints.up("md"));
  const { enqueueSnackbar } = useSnackbar();
  const { isAdmin } = useGroupValidation();
  const { pathname } = useLocation();
  const { register, handleSubmit } = useForm<FormInputs>({
    defaultValues: {
      search: "",
    },
  });
  const { data, error, mutate } = useSWR(
    () => `/media`,
    () => mediaService.getAllMedia(),
    {
      onError: (e) => {
        handleError(
          e,
          "An unexpected error happened while fetching the podcasts. Please try again later;"
        );
        navigate("/");
      },
    }
  );
  const loading = [data, error].every(isUndefined);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [mediaToDelete, setMediaToDelete] = useState<IMediaItemPreview | null>(
    null
  );
  const [isDeleting, setDeleting] = useState(false);

  const onSubmit: SubmitHandler<FormInputs> = ({ search }) => {
    setSearchInputValue(search);
  };

  const podcastListData = useMemo(() => {
    switch (type) {
      case PodcastListType.Deals:
        return data?.filter((item) => Boolean(item.fundId));
      case PodcastListType.Emergent:
        return data?.filter((item) => !item.fundId);
    }
  }, [data, type]);

  const filteredData = useMemo(() => {
    if (!podcastListData) return [];
    if (!searchInputValue) return podcastListData;

    return podcastListData.filter(
      ({ title, description }) =>
        title?.toLowerCase()?.includes(searchInputValue?.toLowerCase()) ||
        description?.toLowerCase()?.includes(searchInputValue?.toLowerCase())
    );
  }, [podcastListData, searchInputValue]);

  const handleDelete = useCallback(
    async (mediaId: string) => {
      setDeleting(true);

      try {
        await mediaService.deletePodcast(mediaId);
        enqueueSnackbar("The podcast has been deleted!", {
          title: "Deleted!",
          variant: "success",
        });
      } catch (e) {
        handleError(e, "It was not possible to delete the podcast.");
      } finally {
        await mutate();
        setMediaToDelete(null);
        setDeleting(false);
      }
    },
    [enqueueSnackbar, handleError, mutate]
  );

  return (
    <>
      <SEOHelmet
        title="Emergent Investments Podcast"
        description="Tune In to Emergent Investments: Your Go-To Source for In-Depth Discussions and Thought-Leading Perspectives on the GP-Led Secondary Market"
        isIndexable
        includeCanonical
      />
      <div className="w-full">
        <PageHeaderAction />
        {loading ? (
          <Loading full />
        ) : (
          <div className="m9-container py-0">
            <form
              className="flex justify-between items-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="w-full md:w-[365px]">
                <SearchInput
                  placeholder="Search by title or description"
                  inputProps={register("search")}
                />
              </div>
              {isAdmin && (
                <Link to={"/" + paths.podcastCreate}>
                  <Button
                    className="hidden md:flex"
                    startIcon={<Add />}
                    variant="contained"
                  >
                    Add podcast
                  </Button>
                  <IconButton
                    className="flex md:hidden bg-primary justify-center ml-8 items-center hover:bg-primary-300 h-12 w-12 text-gray-100"
                    color="primary"
                  >
                    <Add />
                  </IconButton>
                </Link>
              )}
            </form>

            <div className="mt-9">
              <LinkTabs fullWidth={breakpointMdOrMore} value={pathname}>
                {items.map(({ label, to }, index) => {
                  return (
                    <Tab
                      key={index}
                      value={"/" + to}
                      className="text-[16px] py-6 px-2 font-bold capitalize hover:text-primary-400 transition-colors ease-in"
                      component={Link}
                      to={"/" + to}
                      label={label}
                    />
                  );
                })}
              </LinkTabs>
            </div>

            <div className="mt-3">
              {filteredData.length > 0 ? (
                filteredData.map((mediaItem, index) => (
                  <div key={index} className="mb-7">
                    <PodcastCard
                      onDelete={() => setMediaToDelete(mediaItem)}
                      onReload={() => mutate()}
                      media={mediaItem}
                    />
                  </div>
                ))
              ) : (
                <EmptyContentScreen
                  title="No podcast found"
                  subtitle={
                    <div className="text-center">
                      There are no podcast for the current search.
                    </div>
                  }
                  icon={<PodcastsOutlined />}
                />
              )}
            </div>
          </div>
        )}
        <ModalComponent
          title="Delete podcast"
          open={Boolean(mediaToDelete)}
          onClose={() => setMediaToDelete(null)}
          smallTitle
        >
          <div className="flex flex-col h-full">
            <Typography variant="body2" className="text-dark-text">
              Are you sure to delete podcast <b>"{mediaToDelete?.title}"</b>?
            </Typography>
            <div className="w-full justify-end flex flex-col space-y-2 pt-8 mt-auto">
              <LoadingButton
                onClick={() => handleDelete(mediaToDelete?.id as string)}
                variant="contained"
                startIcon={<DeleteForever />}
                loading={isDeleting}
                color="primary"
              >
                Delete
              </LoadingButton>
              <Button
                disabled={isDeleting}
                onClick={() => setMediaToDelete(null)}
                variant="outlined"
              >
                Cancel
              </Button>
            </div>
          </div>
        </ModalComponent>
      </div>
    </>
  );
};

export default Podcasts;

import { lazy } from "react";
import { Route } from "react-router-dom";
import * as paths from "ui/Router/paths";
import { PodcastListType } from "ui/pages/Podcasts/Podcasts";
import { RestrictedByFeatureFlagOutlet } from "ui/Router/guards/RestrictedByFeatureFlagOutlet";
import { FeatureFlagId } from "domain/featureFlag";

const AsyncLandingAI = lazy(() => import("ui/pages/Landing/LandingAI"));
const AsyncLandingAIWaitList = lazy(
  () => import("ui/pages/Landing/LandingAIWaitList")
);

const AsyncPodcasts = lazy(() => import("ui/pages/Podcasts/Podcasts"));

const AsyncLandingInvest = lazy(
  () => import("ui/pages/LandingInvest/LandingInvest")
);
const AsyncLandingInvestAdvancedCalculator = lazy(
  () => import("ui/pages/LandingInvestAdvancedCalculator")
);
const AsyncLandingSyndicateAdvancedCalculator = lazy(
  () => import("ui/pages/LandingSyndicateAdvancedCalculator")
);
const AsyncLandingSyndicate = lazy(
  () => import("ui/pages/LandingSyndicate/LandingSyndicate")
);

export const mainTenantOnlyRoutes = [
  <Route
    key="mainTenantOnlyRoutes"
    element={
      <RestrictedByFeatureFlagOutlet featureFlag={FeatureFlagId.Deals} />
    }
  >
    <Route path={paths.landingInvest}>
      <Route index element={<AsyncLandingInvest />} />
      <Route
        path="calculator"
        element={<AsyncLandingInvestAdvancedCalculator />}
      />
    </Route>
    <Route path={paths.landingISyndicate}>
      <Route index element={<AsyncLandingSyndicate />} />
      <Route
        path="calculator"
        element={<AsyncLandingSyndicateAdvancedCalculator />}
      />
    </Route>
    <Route path={paths.landingAi} element={<AsyncLandingAI />} />
    <Route
      path={paths.landingAIWaitList}
      element={<AsyncLandingAIWaitList />}
    />
    <Route
      path={paths.podcasts}
      element={<AsyncPodcasts type={PodcastListType.Emergent} />}
    />
    <Route
      path={paths.dealPodcasts}
      element={<AsyncPodcasts type={PodcastListType.Deals} />}
    />
  </Route>,
];

import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import { DealAssistantGenerationStatus } from "domain/deal";
import { isEmpty } from "lodash";
import { createContext, Fragment, Suspense } from "react";
import { generatePath, Link, Outlet, useNavigate } from "react-router-dom";
import { DealGeneralData, DealPermissions } from "types/deal";
import { BetaTag } from "ui/components/BetaTag";
import { LINK_TABS_HEIGHT, LinkTabs } from "ui/components/LinkTabs";
import {
  useCurrentPath,
  useDealGeneralData,
  useDealPermissions,
  useSyndicationLayout,
} from "ui/hooks";
import useDealAlias from "ui/hooks/Deal/useDealAlias";
import useDealMetric from "ui/hooks/Deal/useDealMetric";
import * as paths from "ui/Router/paths";

interface TabItemDef {
  label: string;
  to: string;
  permissionsRequired: Array<keyof DealPermissions>;
  subPaths: string[];
  validateDeal?: (deal?: DealGeneralData) => boolean;
  featureFlag?: string;
  hiddenToSyndication?: boolean;
  beta?: boolean;
}

const items: TabItemDef[] = [
  {
    label: "Deal",
    to: paths.dealDetails,
    permissionsRequired: [],
    subPaths: [],
  },
  {
    label: "Q & A",
    to: paths.dealQuestionsAndAnswers,
    permissionsRequired: ["fundAccess"],
    subPaths: [],
    validateDeal: (deal) => Boolean(deal?.isQuestionAndAnswersEnabled),
  },
  {
    label: "Ask",
    to: paths.dealAsk,
    permissionsRequired: ["isParticipant"],
    subPaths: [],
    featureFlag: "deal_ai_chat",
    hiddenToSyndication: true,
  },
  {
    label: "Manage",
    to: paths.dealManage,
    permissionsRequired: ["edit"],
    subPaths: [],
  },
  {
    label: "Assistant",
    to: paths.dealAssistant,
    permissionsRequired: ["dataRoomAccess"],
    subPaths: [],
    validateDeal: (deal) => Boolean(deal?.isAssistantEnabled),
  },
  {
    label: "Funding",
    to: paths.dealDashboard,
    permissionsRequired: ["edit"],
    subPaths: [],
  },
  {
    label: "Reports",
    to: paths.dealReports,
    subPaths: [
      paths.dealReportDataRoomPerformance,
      paths.dealReportPerformance,
    ],
    permissionsRequired: ["edit"],
  },
  {
    label: "Data Room",
    to: paths.dealDataroomVdr,
    permissionsRequired: ["dataRoomAccess"],
    subPaths: [paths.dealDataroomDirectories],
  },
];

export const DealDetailsWrapperContext = createContext<{
  sendMetric: (fileName?: string) => void;
}>({ sendMetric: () => null });

export const DealDetailsWrapper = () => {
  const dealAlias = useDealAlias();
  const currentPath = useCurrentPath();
  const { permissions } = useDealPermissions();
  const { data } = useDealGeneralData();
  const { isSyndicate } = useSyndicationLayout();
  const navigate = useNavigate();

  const { sendMetric } = useDealMetric();

  const currentTabValue =
    items.find(({ to, subPaths }) =>
      [to, ...subPaths].includes(currentPath || "")
    )?.to || items[0]?.to;

  if (
    data?.assistantGenerationStatus &&
    [
      DealAssistantGenerationStatus.Generating,
      DealAssistantGenerationStatus.Error,
    ].includes(data.assistantGenerationStatus)
  ) {
    navigate(
      "/" + generatePath(paths.dealCreateAIGenerating, { alias: dealAlias })
    );

    return null;
  }

  return (
    <Box sx={{ width: "100%" }}>
      {!permissions ? (
        <div style={{ height: LINK_TABS_HEIGHT, width: "100%" }}>&nbsp;</div>
      ) : (
        <LinkTabs value={currentTabValue}>
          {items.map(
            (
              {
                label,
                permissionsRequired,
                to,
                validateDeal,
                featureFlag,
                hiddenToSyndication,
                beta,
              },
              index
            ) => {
              const hasPermissions =
                isEmpty(permissionsRequired) ||
                permissionsRequired.every((key) => permissions?.[key]);

              if (isSyndicate && hiddenToSyndication) {
                return null;
              }

              if (validateDeal && !validateDeal(data)) {
                return null;
              }

              if (!hasPermissions) {
                return null;
              }

              // TODO: temporary, remove it and uncomment the commented lines below.
              if (featureFlag) {
                return null;
              }

              return (
                <Tab
                  key={index}
                  value={to}
                  className="text-[16px] py-6 px-2 font-bold capitalize hover:text-primary-400 transition-colors ease-in"
                  component={Link}
                  to={generatePath("/" + to, { alias: dealAlias })}
                  label={
                    <div className="flex items-center justify-center space-x-1">
                      <div>{label}</div>
                      {beta && <BetaTag />}
                    </div>
                  }
                />
              );
            }
          )}
        </LinkTabs>
      )}

      <div
        className="m9-container grow flex flex-col"
        style={{ height: `calc(100% - ${LINK_TABS_HEIGHT})` }}
      >
        <Suspense fallback={<Fragment />}>
          <DealDetailsWrapperContext.Provider value={{ sendMetric }}>
            <Outlet />
          </DealDetailsWrapperContext.Provider>
        </Suspense>
      </div>
    </Box>
  );
};

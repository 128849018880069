import autoAnimate from "@formkit/auto-animate";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { Children, FC, ReactNode, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { IAssistantMessage } from "types/assistant.service";
import { EnterButton } from "../Button";
import { SearchInput } from "../SearchInput";

export interface IAssistantChatMessage
  extends Pick<IAssistantMessage, "messageId" | "role" | "createdAt"> {
  content: string;
  textToCopy?: string;
  error?: boolean;
}

interface AssistantFloatingChatProps {
  onMessageAdd: (message: string) => Promise<{ isSuccess: boolean }>;
  isMinimized?: boolean;
  isLoading: boolean;
  disabled?: boolean;
  disclaimer?: string;
  children?: ReactNode[];
  formToolbar?: ReactNode;
  animate?: boolean;
  header: ReactNode;
}

export const AssistantFloatingChat: FC<AssistantFloatingChatProps> = ({
  isLoading,
  disabled,
  children,
  onMessageAdd,
  isMinimized,
  formToolbar,
  header,
  disclaimer = "Assistant won't always get it right.",
  animate = false,
}) => {
  const parentElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentElement.current && animate) {
      autoAnimate(parentElement.current);
    }
  }, [animate]);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: { message: "" },
  });

  // const hasError = useMemo(() => {
  //   return messages?.[0]?.error;
  // }, [messages]);

  return (
    <div className="flex flex-col h-full">
      {header}
      {!isMinimized && [
        <div
          key="content"
          className="grow flex flex-col-reverse px-4 overflow-auto"
          ref={parentElement}
        >
          <div className="h-px shrink-0 mb-auto w-full">&nbsp;</div>
          {[...Children.toArray(children)].reverse()}
        </div>,
        <div key="form" className="relative items-center bg-white py-4 px-4">
          {formToolbar}
          <div
            className={clsx(
              "w-full flex flex-nowrap space-x-2 border-0",
              disabled && "opacity-40"
            )}
          >
            <form
              onSubmit={handleSubmit(async ({ message }) => {
                reset();

                const { isSuccess } = await onMessageAdd(message);

                if (!isSuccess) reset({ message });
              })}
              className="w-full flex"
            >
              <Controller
                control={control}
                name="message"
                render={({ field }) => (
                  <SearchInput
                    disabled={disabled || isLoading}
                    inputProps={field}
                    placeholder="Add new message"
                    buttonType="submit"
                    className="py-1 w-full"
                    autoFocus
                    customButton={
                      <EnterButton
                        className="h-4 px-5"
                        type="submit"
                        disabled={disabled || isLoading}
                        loading={isLoading}
                      >
                        <Typography variant="button2" className="text-inherit">
                          Send
                        </Typography>
                      </EnterButton>
                    }
                  />
                )}
              />
            </form>
          </div>
          <div className="mt-4 flex items-center text-center justify-center w-full">
            <Typography
              variant="caption"
              className="flex items-center text-center justify-center text-gray-400"
            >
              {disclaimer}
            </Typography>
          </div>
        </div>,
      ]}
    </div>
  );
};

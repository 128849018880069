import { lazy } from "react";
import { Route } from "react-router-dom";
import { RestrictedByGroupOutlet } from "ui/Router/guards/RestrictedByGroupOutlet";

const PodcastCreate = lazy(() => import("ui/pages/PodcastCreate"));
const PodcastReview = lazy(() => import("ui/pages/PodcastReview"));
const PodcastDetail = lazy(() => import("ui/pages/PodcastDetail"));
const PodcastEdit = lazy(() => import("ui/pages/PodcastEdit"));
const PodcastEditTranscription = lazy(
  () => import("ui/pages/PodcastEditTranscription")
);

export const podcastRoutes = [
  <Route key="podcastRoutes" path="podcast">
    <Route element={<RestrictedByGroupOutlet admin />}>
      <Route path="create" element={<PodcastCreate />} />
    </Route>
    <Route path=":mediaId">
      <Route index element={<PodcastDetail />} />
      <Route element={<RestrictedByGroupOutlet admin />}>
        <Route path="edit">
          <Route index element={<PodcastEdit />} />
          <Route path="transcription" element={<PodcastEditTranscription />} />
          <Route path="review" element={<PodcastReview />} />
        </Route>
      </Route>
    </Route>
  </Route>,
];

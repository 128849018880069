import React from "react";
import NavigationItem, { NavigationItemProps } from "./NavigationItem";

interface Props {
  items: NavigationItemProps[];
}

export const Navigation = ({ items }: Props) => {
  const primaryItems = items.filter((item) => !item.navItems?.length);
  const secondaryItems = items.filter((item) => item.navItems?.length);

  return (
    <div className="flex flex-col lg:flex-row lg:justify-between w-full gap-8">
      <div className="grid grid-flow-row md:grid-flow-col gap-8 auto-rows-max">
        {Boolean(primaryItems.length) &&
          primaryItems.map((item, index) => (
            <NavigationItem key={index} {...item} />
          ))}
      </div>

      <div className="hidden lg:block w-[1px] bg-gray-200" />

      <div className="grid grid-flow-row md:grid-flow-col gap-8 md:gap-12 min-w-[268px]">
        {Boolean(secondaryItems.length) &&
          secondaryItems.map((item, index) => (
            <NavigationItem key={index} {...item} />
          ))}
      </div>
    </div>
  );
};
export default Navigation;

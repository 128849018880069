import type { SVGProps } from "react";
const SvgBullpenLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    width={24}
    height={24}
    {...props}
  >
    <path d="M.878 5.923a.126.126 0 0 0 0 .228l11.064 5.41c.036.017.08.017.116 0l3.283-1.605a.126.126 0 0 0-.002-.23l-7.71-3.618a.126.126 0 0 1-.001-.229l3.878-1.896a.13.13 0 0 1 .115-.001l8.074 3.79a.13.13 0 0 0 .115-.002l3.312-1.619a.126.126 0 0 0 0-.228L12.058.513a.13.13 0 0 0-.116 0zM16.174 10.895a.13.13 0 0 0-.189-.114l-3.391 1.658a.13.13 0 0 0-.072.114v10.82a.13.13 0 0 0 .188.113l3.392-1.658a.13.13 0 0 0 .072-.114zM20.537 19.66a.13.13 0 0 1-.19-.114V8.727c0-.048.029-.093.073-.114l3.391-1.658a.13.13 0 0 1 .189.114v10.819a.13.13 0 0 1-.072.114zM0 17.888c0 .048.028.092.072.114l11.218 5.484a.13.13 0 0 0 .188-.114V12.554a.13.13 0 0 0-.072-.115L.19 6.955A.13.13 0 0 0 0 7.069z" />
  </svg>
);
export default SvgBullpenLogoIcon;

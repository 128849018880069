import { Dispatch } from "redux";
import { UseUserProfile } from "types/user.service";
import UserService from "services/user.service";
import { RootState } from "../rootReducer";
import { FetchStatus } from "domain/common";

export enum ActionType {
  FetchUserProfileStarted = "FetchUserProfileStarted",
  FetchUserProfileFinished = "FetchUserProfileFinished",
  FetchUserProfileError = "FetchUserProfileError",
  SetUserProfile = "SetUserProfile",
}

export const fetchUserProfileStarted = () => ({
  type: ActionType.FetchUserProfileStarted,
});

export const fetchUserProfileFinished = () => ({
  type: ActionType.FetchUserProfileFinished,
});

export const fetchUserProfileError = () => ({
  type: ActionType.FetchUserProfileError,
});

export const setUserProfile = (payload: UseUserProfile) => ({
  type: ActionType.SetUserProfile,
  payload,
});

type UserActions = ReturnType<
  | typeof fetchUserProfileStarted
  | typeof fetchUserProfileFinished
  | typeof fetchUserProfileError
  | typeof setUserProfile
>;

//=============================================================================
//Async Operations
//=============================================================================

export const fetchUserProfile = () => {
  return async (dispatch: Dispatch<UserActions>, getState: () => RootState) => {
    const state = getState();
    const fetchProfileStatus = state.user.fetch.profile;

    if (fetchProfileStatus !== FetchStatus.Started) {
      dispatch(fetchUserProfileStarted());

      try {
        const { response } = await UserService.getUserProfile();

        if (response) {
          const { firstName, lastName } = response;
          const fullName = `${firstName || ""} ${lastName || ""}`;
          const firstNameLetter = firstName?.[0]?.toUpperCase() ?? "";
          const lastNameLetter = lastName?.[0]?.toUpperCase() ?? "";
          const initials = firstNameLetter + lastNameLetter;

          dispatch(setUserProfile({ ...response, fullName, initials }));
          dispatch(fetchUserProfileFinished());
        } else {
          dispatch(fetchUserProfileError());
        }
      } catch (error) {
        dispatch(fetchUserProfileError());
      }
    }
  };
};

import * as paths from "ui/Router/paths";
import { NavigationItemProps } from "ui/components";
import { CommonLinks } from "domain/common";
import { FeatureFlagId } from "domain/featureFlag";
import { getBullpenNavItems } from "bullpen/navigation/bullpenNavItems";
import { isBullpen } from "application/platformConfiguration";
import { ReactComponent as IconFolderSVG } from "assets/svg/icon-folder.svg";
import { ReactComponent as PieChartSVG } from "assets/svg/icon-chart.svg";
import { ReactComponent as GridSVG } from "assets/svg/icon-grid.svg";

export const getNavItems = (isAuthorized: boolean): NavigationItemProps[] => {
  if (isBullpen()) {
    return getBullpenNavItems(isAuthorized);
  }

  if (isAuthorized) {
    return [
      {
        title: "Deals list",
        description:
          "Check out all the deals on the platform and invest in what catches your eye!",
        featureFlag: FeatureFlagId.Deals,
        to: "/" + paths.dealsAll,
        icon: GridSVG,
      },
      {
        title: "Projects list",
        description:
          "Create and collaborate with other projects and work on various reports and files",
        featureFlag: FeatureFlagId.Projects,
        restrictredByGroup: ["admin"],
        to: "/" + paths.projectList,
        icon: IconFolderSVG,
      },
      {
        title: "Portfolio overwiew",
        description:
          "See full statistic on your portfolio and make important changes",
        featureFlag: FeatureFlagId.Deals,
        to: "/" + paths.portfolioPath,
        icon: PieChartSVG,
      },
      {
        title: "Settings",
        navItems: [
          {
            text: "Notifications",
            to: "/" + paths.notificationsPath,
          },
          {
            text: "Preferences",
            to: "/" + paths.preferencesPath,
          },
          {
            text: "Invite your contacts",
            to: "/" + paths.inviteContacts,
          },
        ],
      },
    ];
  }

  return [
    // {
    //   title: "About us",
    //   navItems: [
    //     {
    //       text: "How it works",
    //     },
    //     {
    //       text: "About us",
    //     },
    //     {
    //       text: "Contact",
    //     },
    //   ],
    // },
    {
      title: "Home",
      hiddenInLargerScreen: true,
      hiddenForSyndicate: true,
      navItems: [
        {
          text: "Invest",
          to: paths.landingInvest,
        },
        {
          text: "Syndicate",
          to: paths.landingISyndicate,
        },
        {
          text: "AI",
          to: paths.landingAi,
        },
        {
          text: "Podcasts",
          to: paths.podcasts,
        },
        {
          text: "Blog",
          to: CommonLinks.Blog,
          external: true,
        },
      ],
    },
    {
      title: "Start now",
      navItems: [
        {
          text: "Sign up",
          to: paths.signUpPath,
        },
        {
          text: "Login",
          to: paths.signInPath,
        },
      ],
    },
  ];
};

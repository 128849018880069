import React from "react";
import clsx from "clsx";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import UserMenu from "./UserMenu/UserMenu";
import Fade from "@mui/material/Fade";
import { CurrentUserAvatar } from "ui/components";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as IconNotifications } from "assets/svg/icon-notifications.svg";
import { notificationsPath } from "ui/Router/paths";
import { Badge } from "@mui/material";
import { useUnreadNotificationCount } from "ui/hooks/useUnreadNotificationCount";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { State as PopperState, ModifierPhases } from "@popperjs/core";
import Modal from "@mui/material/Modal";
import { useTypedSelector } from "../../../../hooks/useTypeSelector";
import { noop } from "lodash";
import * as paths from "ui/Router/paths";

interface AuthorizedBlockProps {
  headerRef: React.MutableRefObject<HTMLDivElement | null>;
  light?: boolean;
  hideActions: boolean;
}

export const NAV_PROFILE_BUTTON_ID = "nav-profile-button";

const AuthorizedBlock = ({
  headerRef,
  light,
  hideActions,
}: AuthorizedBlockProps) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const anchorToHeader = smallScreen && headerRef.current;
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);

  const { isOpenProfileDisabled } = useTypedSelector((state) => state.UI);

  const { data: unreadCountResponse } = useUnreadNotificationCount({
    refreshInterval: 300000,
  });

  const notificationsCount = React.useMemo(() => {
    return unreadCountResponse?.data.response?.count;
  }, [unreadCountResponse]);

  React.useEffect(() => {
    closeMenu();
  }, [location]);

  const onProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    if (hideActions) return;

    setOpen(!open);
    if (!open) {
      setAnchorEl(anchorToHeader ? headerRef.current : event.currentTarget);
    }
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const smallScreenModifier = {
    name: "smallScreenModifier",
    enabled: true,
    phase: "write" as ModifierPhases,
    fn({ state }: { state: PopperState }) {
      if (anchorToHeader) {
        const m9Header = document.getElementById("m9-header") as HTMLElement;
        const height = `calc(100% - ${m9Header.offsetHeight}px)`;
        state.styles.popper = {
          ...state.styles.popper,
          width: "100%",
          height,
        };
      }
      return state;
    },
  };

  const isBullpenProductPage = location.pathname === `/${paths.bullpenProduct}`;

  return (
    <div className="flex items-center">
      {!hideActions && (
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/" + notificationsPath);
          }}
        >
          <Badge
            className="mr-4 text-gray-500 hover:text-black"
            color="primary"
            badgeContent={notificationsCount}
            max={99}
          >
            <IconNotifications
              className={clsx({
                "fill-white hover:fill-gray-300": light,
                "fill-gray-400 hover:fill-primary-400":
                  !light || (light && isBullpenProductPage),
              })}
            />
          </Badge>
        </div>
      )}
      <CurrentUserAvatar
        id={NAV_PROFILE_BUTTON_ID}
        className="inline-flex h-8 w-8 text-[1rem] cursor-pointer"
        onClick={isOpenProfileDisabled ? undefined : onProfileClick}
      />
      <Popper
        className={clsx({})}
        open={!smallScreen && open}
        anchorEl={anchorEl}
        placement={anchorToHeader ? "bottom-start" : "bottom-end"}
        transition
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
        modifiers={[smallScreenModifier]}
        placeholder=""
        onPointerEnterCapture={noop}
        onPointerLeaveCapture={noop}
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            timeout={{
              enter: 350,
              exit: 350,
            }}
          >
            <Paper className="w-full h-full rounded-none sm:rounded-[12px] shadow-none sm:shadow-menu border-0 sm:border sm:mt-2 bg-gray-100">
              <UserMenu closeMenu={closeMenu} />
            </Paper>
          </Fade>
        )}
      </Popper>
      <Modal
        className={clsx({
          "top-[57px]": smallScreen,
          "top-[67px]": !smallScreen,
        })}
        open={smallScreen && open}
        onClose={closeMenu}
        closeAfterTransition
        hideBackdrop
        disableAutoFocus
      >
        <Fade in={smallScreen && open}>
          <Paper className="w-full h-full rounded-none sm:rounded-[12px] shadow-none sm:shadow-menu border-0 sm:border sm:mt-2 bg-gray-100">
            <UserMenu closeMenu={closeMenu} />
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

export default AuthorizedBlock;

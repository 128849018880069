import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BullpenLeadGenerationFormState {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  industry: string;
  role: string;
  countryCode: string;
  subscribe: boolean;
}

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  industry: "",
  role: "",
  countryCode: "",
  subscribe: false,
};

const bullpenLeadGenerationFormSlice = createSlice({
  name: "bullpenLeadGenerationForm",
  initialState,
  reducers: {
    setLeadGenerationForm: (
      state,
      {
        payload,
      }: PayloadAction<Omit<BullpenLeadGenerationFormState, "subscribe">>
    ) => {
      return { ...state, ...payload };
    },
    resetLeadGenerationForm: () => {
      return { ...initialState };
    },
  },
});

export const { setLeadGenerationForm, resetLeadGenerationForm } =
  bullpenLeadGenerationFormSlice.actions;
export default bullpenLeadGenerationFormSlice.reducer;

import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { DealCardPayload } from "types/deal";
import { DealCard } from "ui/components";

interface Props {
  deals: DealCardPayload[];
  from?: string;
}

const OtherDeals = ({ deals, from }: Props) => {
  if (isEmpty(deals)) return null;

  return (
    <div className="pt-6">
      <Typography
        variant="hairline2"
        className="text-gray-300 block pb-4 uppercase"
      >
        {"Other Deals" + (from && ` from ${from}`)}
      </Typography>
      <div>
        <Swiper
          modules={[Pagination]}
          spaceBetween={20}
          pagination={{ clickable: true }}
          breakpoints={{
            "320": {
              slidesPerView: 1,
            },
            "1200": {
              slidesPerView: 2,
            },
          }}
        >
          {deals.map((deal, index) => (
            <SwiperSlide key={index} className="sm:pr-[1px] box-border">
              <DealCard
                deal={deal}
                className="mb-[30px]"
                dealCardClassName="w-[inherit]"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default OtherDeals;

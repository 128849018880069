import { Collapse, useMediaQuery, useTheme } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { getSearchParams } from "application/searchParams";
import { SESSION_STORAGE_AVOID_PAGE_LOADING_KEY } from "domain/common";
import { FC, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { setOriginInviteCode } from "redux/Invite/reducer";
import { useSWRConfig } from "swr";
import * as paths from "ui/Router/paths";
import { AssistantFloatingChatProvider } from "ui/components/AssistantFloatingChat/AssistantFloatingChat.context";
import { ErrorBoundary } from "ui/components/ErrorBoundary";
import { useTypedSelector } from "ui/hooks/useTypeSelector";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import SnackbarCookies from "../../components/SnackbarCookies/SnackbarCookies";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import { useSyndicationLayout } from "../../hooks";
import NotFound from "../NotFound/NotFound";
import { PODCAST_TYPES } from "../Podcasts/contants";
import { BetaBanner } from "./CommonLayout.components";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Modals from "./Modals/Modals";
import styles from "./styles.module.scss";
import { hideBetaBanner } from "redux/UI/reducer";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";
import { clsxm } from "application/utils";
import { isBullpen } from "application/platformConfiguration";

const CommonLayout: FC<{ hasLayoutError: boolean }> = ({ hasLayoutError }) => {
  const theme = useTheme();
  const { authorized } = useTypedSelector((state) => state.auth);
  const { forceWhiteHeader, isBetaBannerHidden, isContentFullScreen } =
    useTypedSelector(({ UI }) => UI);
  const { isSyndicate } = useSyndicationLayout();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const { cache } = useSWRConfig();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [onTop, setOnTop] = useState(true);
  const [cookieSnackbarOpen, setCookieSnackbarOpen] = useState(
    () => localStorage.getItem("cookiesAcceptedSnackbar") !== "true"
  );
  const breakpointMdOrMore = useMediaQuery(theme.breakpoints.up("md"));
  const { isDealsOn } = useFeatureFlag();

  const { hideFooterPaths, hideHeaderPaths, showAIBannerPaths } =
    useMemo(() => {
      const showAIBannerPaths = [
        "",
        paths.landingISyndicate,
        paths.landingInvest,
        paths.landingInvestCalculator,
        paths.landingSyndicateCalculator,
        paths.signInPath,
        paths.signUpPath,
        paths.signUpInvite,
      ];

      if (isSyndicate) {
        return {
          hideFooterPaths: [
            "",
            paths.signInPath,
            paths.signUpPath,
            paths.firstSteps,
            paths.landingAIWaitList,
            paths.landingAi,
          ],
          hideHeaderPaths: [
            "",
            paths.signInPath,
            paths.landingAi,
            paths.landingAIWaitList,
          ],
          showAIBannerPaths,
        };
      }

      return {
        hideFooterPaths: [
          paths.signInPath,
          paths.signUpPath,
          paths.firstSteps,
          paths.landingAIWaitList,
          paths.landingAi,
        ],
        hideHeaderPaths: [paths.landingAi, paths.landingAIWaitList],
        showAIBannerPaths,
      };
    }, [isSyndicate]);

  const hideElements = useCallback(
    (pathList: string[]) => {
      return pathList
        .map((path: string) => `/${path}`)
        .some((path) => path === pathname);
    },
    [pathname]
  );

  useEffect(() => {
    const typedCache = cache as Map<string, any>;
    if (!authorized && typedCache.size) {
      (cache as Map<string, any>).clear();
      sessionStorage.removeItem(SESSION_STORAGE_AVOID_PAGE_LOADING_KEY);
    }
  }, [cache, authorized]);

  useEffect(() => {
    const { inviteCode, invitecode } = getSearchParams();
    const validInvalidCode = inviteCode || invitecode;
    if (validInvalidCode) {
      dispatch(setOriginInviteCode(validInvalidCode));
    }
  }, [dispatch]);

  const mediaItem = useTypedSelector(
    (state) => state.mediaPlayer.currentMediaItem
  );

  const isHeaderTransparent = useMemo(() => {
    return [
      "",
      paths.landingInvest,
      paths.landingISyndicate,
      paths.landingAi,
      paths.landingAIWaitList,
      ...(isBullpen() ? [paths.bullpenProduct] : []),
    ].includes(pathname.replace(/^\//, ""));
  }, [pathname]);

  const isHeaderHidden = hideElements(hideHeaderPaths);
  const showAIBanner = hideElements(showAIBannerPaths);

  useEffect(() => {
    if (containerRef) {
      const onContainerScroll = (event: Event) => {
        const { scrollTop } = event.target as HTMLDivElement;

        setOnTop(Math.abs(scrollTop) < (breakpointMdOrMore ? 200 : 50));
      };

      containerRef.addEventListener("scroll", onContainerScroll);

      return () => {
        containerRef.removeEventListener("scroll", onContainerScroll);
        setOnTop(true);
      };
    }
  }, [breakpointMdOrMore, containerRef]);

  const handleCloseCookiesSnackbar = () => {
    setCookieSnackbarOpen(false);
    localStorage.setItem("cookiesAcceptedSnackbar", "true");
  };

  const isBullpenProductPage = pathname === `/${paths.bullpenProduct}`;

  const hideFooter =
    (isBullpen() && isBullpenProductPage) || (isBullpen() && pathname === "/");

  return (
    <AssistantFloatingChatProvider>
      <div
        ref={(ref) => setContainerRef(ref)}
        className={clsxm("h-screen overflow-auto flex flex-col bg-gray-100", {
          "pb-[155px] md:pb-[166px]": !!mediaItem,
        })}
      >
        {showAIBanner && !isBetaBannerHidden && isDealsOn && !isBullpen() && (
          <BetaBanner onClose={() => dispatch(hideBetaBanner())} dark />
        )}
        <Collapse
          in={!isContentFullScreen}
          className="sticky top-0 z-50 !min-h-[unset]"
        >
          {!(isHeaderTransparent || isHeaderHidden) && <Toolbar />}
          <div className="absolute top-0 left-0 w-full">
            <Header
              hide={isHeaderHidden}
              transparent={!forceWhiteHeader && onTop && isHeaderTransparent}
            />
          </div>
        </Collapse>

        <Modals />
        <div className={clsxm(styles.container, "grow flex")}>
          <ErrorBoundary key={pathname} navigate={navigate}>
            <Suspense fallback={<></>}>
              {hasLayoutError ? <NotFound /> : <Outlet />}
            </Suspense>
          </ErrorBoundary>
          {mediaItem && mediaItem.type === PODCAST_TYPES.AUDIO && (
            <AudioPlayer {...mediaItem} />
          )}
          {mediaItem && mediaItem.type === PODCAST_TYPES.VIDEO && (
            <VideoPlayer {...mediaItem} />
          )}
        </div>
        {!hideFooter && (
          <Footer
            hide={hideElements(hideFooterPaths) || Boolean(hasLayoutError)}
          />
        )}
        <SnackbarCookies
          open={cookieSnackbarOpen}
          handleClose={handleCloseCookiesSnackbar}
        />
      </div>
    </AssistantFloatingChatProvider>
  );
};

export default CommonLayout;

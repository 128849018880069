import React, { SVGProps, VFC } from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as IconArrowDownSimpleLineSVG } from "assets/svg/icon-arrow-down-simple-line.svg";
import { ReactComponent as IconCloseLineSVG } from "assets/svg/icon-close-line.svg";
import { useLocation, useNavigate } from "react-router-dom";
import HideOnScroll from "../HideOnScroll";
import { useSyndicationLayout } from "ui/hooks";

export interface NavigationDrawerItem {
  label: string;
  Icon?:
    | VFC<SVGProps<SVGSVGElement>>
    | (OverridableComponent<SvgIconTypeMap> & { muiName: string });
  to?: string;
  onClick?: () => void;
  hiddenOnSyndication?: boolean;
}

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  title?: string;
  items: NavigationDrawerItem[];
}

export const NavigationDrawer = ({ title, className, items }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const completePathname = [location.pathname, location.hash].join("");
  const { isSyndicate } = useSyndicationLayout();

  const selectedItemIndex = items.findIndex(
    ({ to }) => to === completePathname
  );

  const label = selectedItemIndex >= 0 ? items[selectedItemIndex].label : "";

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={clsx("z-[50] sticky top-[56px] sm:top-[64px]", className)}>
      <HideOnScroll>
        <div
          className="z-[200] flex items-center relative px-8 py-4 bg-white cursor-pointer"
          onClick={handleToggle}
        >
          <div className="grow">
            {title && (
              <Typography
                className="text-gray-300 font-mono font-bold uppercase"
                variant="hairline2"
              >
                {title}
              </Typography>
            )}
            <Typography
              className="text-primary-400 font-bold capitalize"
              variant="body2"
            >
              {label}
            </Typography>
          </div>
          <div>
            {open ? <IconCloseLineSVG /> : <IconArrowDownSimpleLineSVG />}
          </div>
        </div>
      </HideOnScroll>
      <Drawer
        sx={{
          width: "auto",
          zIndex: 100,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "auto",
          },
        }}
        anchor="top"
        open={open}
        onClose={handleClose}
        disablePortal
      >
        <div className="h-[128px]" />
        <List>
          {items
            .filter((x) => !(isSyndicate && x.hiddenOnSyndication))
            .map(({ label, to, Icon, onClick }, index) => (
              <ListItem key={index}>
                <ListItemButton
                  className="rounded-lg"
                  selected={index === selectedItemIndex}
                  onClick={() => {
                    if (to) navigate(to);
                    if (onClick) onClick();
                    handleClose();
                  }}
                >
                  {Icon && (
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Drawer>
    </div>
  );
};

export default NavigationDrawer;

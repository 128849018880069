import { createTheme, ThemeOptions } from "@mui/material/styles";
import { emerge9TailwindPreset } from "tailwind-preset";

declare module "@mui/material/styles" {
  interface Theme {
    customColors: {
      gray: {
        100: string;
        150: string;
        200: string;
        300: string;
        400: string;
        500: string;
      };
      primary: {
        0: string;
        100: string;
        200: string;
        300: string;
        400: string;
        600: string;
      };
      secondary: {
        0: string;
        100: string;
        200: string;
        300: string;
      };
      text: {
        dark: string;
      };
      semantic: {
        green0: string;
        green200: string;
        blue150: string;
        blue300: string;
        blue500: string;
        pink700: string;
        pink300: string;
        orange150: string;
        orange300: string;
        orange500: string;
        orange700: string;
        yellow150: string;
        yellow300: string;
        yellow500: string;
        yellow900: string;
      };
      complementary: {
        400: string;
        300: string;
        200: string;
        100: string;
      };
      danger: {
        600: string;
        300: string;
        200: string;
        100: string;
      };
    };
  }

  interface ThemeOptions {
    customColors?: {
      text?: {
        dark?: string;
      };
      gray?: {
        100?: string;
        150?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
      };
      secondary?: {
        0?: string;
        100?: string;
        200?: string;
        300?: string;
      };
      primary?: {
        0?: string;
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        600?: string;
      };
      semantic?: {
        green0?: string;
        green200?: string;
        blue150?: string;
        blue300?: string;
        blue500?: string;
        pink700?: string;
        pink300?: string;
        orange150?: string;
        orange300?: string;
        orange500?: string;
        orange700?: string;
        yellow150?: string;
        yellow300?: string;
        yellow500?: string;
        yellow900?: string;
      };
      complementary?: {
        400?: string;
        300?: string;
        200?: string;
        100?: string;
      };
      danger?: {
        600?: string;
        300?: string;
        200?: string;
        100?: string;
      };
    };
  }

  interface TypographyVariants {
    body11: React.CSSProperties;
    link: React.CSSProperties;
    caption: React.CSSProperties;
    caption2: React.CSSProperties;
    button: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
    hairline2: React.CSSProperties;
    hairline1: React.CSSProperties;
    hero: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body11?: React.CSSProperties;
    link?: React.CSSProperties;
    caption?: React.CSSProperties;
    caption2?: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
    hairline2?: React.CSSProperties;
    hairline1?: React.CSSProperties;
    hero?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body11: true;
    link: true;
    caption: true;
    caption2: true;
    button1: true;
    button2: true;
    hairline2: true;
    hairline1: true;
    hero: true;
  }
}

export const getTheme = (
  {
    mainColor,
    mainColor0,
    mainColor100,
    mainColor200,
    mainColor300,
    mainColor400,
    mainColor600,
    textColor,
  }: IBrandingColors,
  tailwindPreset: TailwindPreset = emerge9TailwindPreset
) => {
  const {
    theme: {
      extend: { colors },
      screens,
    },
  } = tailwindPreset;

  const themeObj: ThemeOptions = {
    breakpoints: {
      values: {
        xs: parseInt(screens["xs"]),
        sm: parseInt(screens["sm"]),
        md: parseInt(screens["md"]),
        lg: parseInt(screens["lg"]),
        xl: parseInt(screens["xl"]),
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: mainColor,
      },
      secondary: {
        main: colors["secondary"],
      },
      error: {
        main: colors["danger-300"],
      },
    },
    customColors: {
      gray: {
        500: colors["gray-500"],
        400: colors["gray-400"],
        300: colors["gray-300"],
        200: colors["gray-200"],
        150: colors["gray-150"],
        100: colors["gray-100"],
      },
      primary: {
        0: mainColor0,
        100: mainColor100,
        200: mainColor200,
        300: mainColor300,
        400: mainColor400,
        600: mainColor600,
      },
      text: {
        dark: textColor,
      },
      secondary: {
        "0": colors["secondary-0"],
        "100": colors["secondary-100"],
        "200": colors["secondary-200"],
        "300": colors["secondary-300"],
      },
      danger: {
        600: colors["danger-600"],
        300: colors["danger-300"],
        200: colors["danger-200"],
        100: colors["danger-100"],
      },
      semantic: {
        green0: colors["semantic-green0"],
        green200: colors["semantic-green200"],
        blue150: colors["semantic-blue150"],
        blue300: colors["semantic-blue300"],
        blue500: colors["semantic-blue500"],
        pink700: colors["semantic-pink700"],
        pink300: colors["semantic-pink300"],
        orange150: colors["semantic-orange150"],
        orange300: colors["semantic-orange300"],
        orange500: colors["semantic-orange500"],
        orange700: colors["semantic-orange700"],
        yellow150: colors["semantic-yellow150"],
        yellow300: colors["semantic-yellow300"],
        yellow500: colors["semantic-yellow500"],
        yellow900: colors["semantic-yellow900"],
      },
      complementary: {
        400: colors["complementary-400"],
        300: colors["complementary-300"],
        200: colors["complementary-200"],
        100: colors["complementary-100"],
      },
    },
    typography: {
      fontFamily: ["'IBM Plex Sans'", "sans-serif"].join(","),
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
    },
  };

  // theme variable reuse was done based on MUI team guidelines, but it causes typescript error. Hack below is just to hide error.
  /* eslint-disable */
  // @ts-ignore

  const theme = createTheme(themeObj);

  return createTheme(theme as any, {
    /* eslint-enable */
    typography: {
      body1: {
        fontSize: "1rem",
        lineHeight: 1.2,
      },
      body11: {
        fontSize: "1.5rem",
        lineHeight: "2rem",
        letterSpacing: "-1%",
        color: theme.customColors.primary[600],
        [theme.breakpoints.down("md")]: {
          fontSize: "1.125rem",
          lineHeight: "1.5625rem",
        },
      },
      body2: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        color: theme.customColors.gray[500],
      },
      h1: {
        fontSize: "4rem",
        lineHeight: "4rem",
        fontWeight: 400,
        letterSpacing: "-2%",
        fontFamily: "'IBM Plex Serif', serif",
        [theme.breakpoints.down("md")]: {
          fontSize: "2.5rem",
          lineHeight: "2.75rem",
        },
      },
      h2: {
        fontSize: "3rem",
        lineHeight: "3.5rem",
        fontWeight: 400,
        letterSpacing: "-2%",
        fontFamily: "'IBM Plex Serif', serif",
        [theme.breakpoints.down("md")]: {
          fontSize: "2rem",
          lineHeight: "2.75rem",
        },
      },
      h3: {
        fontSize: "2.5rem",
        lineHeight: "2.875rem",
        fontWeight: 500,
        letterSpacing: "-2%",
        [theme.breakpoints.down("md")]: {
          fontSize: "1.625rem",
          lineHeight: "1.875rem",
        },
      },
      h4: {
        letterSpacing: "-1%",
        lineHeight: "2rem",
        fontSize: "1.75rem",
        fontWeight: 500,
        fontFamily: "'IBM Plex Serif', serif",
        [theme.breakpoints.down("md")]: {
          fontSize: "1.5rem",
          lineHeight: "1.75rem",
        },
      },
      caption: {
        fontSize: "0.875rem",
        lineHeight: "1rem",
        color: theme.customColors.gray[400],
        [theme.breakpoints.down("md")]: {
          lineHeight: "1.5rem",
        },
      },
      caption2: {
        fontSize: "0.75rem",
        lineHeight: "0.875rem",
        color: theme.customColors.gray[300],
      },
      button1: {
        fontWeight: "bold",
        fontSize: "1rem",
        lineHeight: "1rem",
        color: theme.palette.primary.main,
      },
      button2: {
        fontWeight: "bold",
        fontSize: "0.875rem",
        lineHeight: "1rem",
        color: theme.palette.primary.main,
      },
      hairline1: {
        fontFamily: "'IBM Plex Mono', monospace",
        fontSize: "1rem",
        lineHeight: "1rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        [theme.breakpoints.down("md")]: {
          fontSize: "0.875rem",
          lineHeight: "1rem",
        },
      },
      hairline2: {
        fontFamily: "'IBM Plex Mono', monospace",
        fontSize: "0.75rem",
        lineHeight: "0.75rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        [theme.breakpoints.down("md")]: {
          fontSize: "0.6875rem",
          lineHeight: "0.75rem",
          letterSpacing: "-2%",
        },
      },
      link: {
        fontWeight: "600",
        fontSize: "1rem",
        lineHeight: "16px",
        color: theme.customColors.complementary[400],
      },
      hero: {
        fontSize: "5rem",
        lineHeight: "1",
        letterSpacing: "-0.02em",
        [theme.breakpoints.down("sm")]: {
          fontSize: "3rem",
        },
      },
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            fontFamily: "'IBM Plex Mono', monospace",
            fontSize: "0.75rem",
            lineHeight: "0.75rem",
            color: theme.customColors.gray[200],
            fontWeight: "bold",
            "&.Mui-active": {
              color: theme.customColors.gray[300],
            },
            "&.Mui-completed": {
              color: theme.customColors.primary[400],
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            height: 32,
            margin: 0,
            padding: 0,
            width: 64,
            "&.MuiSwitch-sizeSmall": {
              height: 24,
              width: 40,

              thumb: {
                height: 16,
                width: 16,
              },
            },
          },
          switchBase: {
            padding: 4,
            "&.Mui-checked": {
              transform: "translateX(32px)",
              color: theme.customColors.gray[100],

              "& + .MuiSwitch-track": {
                backgroundColor: theme.customColors.primary[200],
                opacity: 1,
              },
            },
          },
          track: {
            borderRadius: "50vh",
          },
          thumb: {
            height: 24,
            width: 24,
          },
          checked: {},
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxSizing: "border-box",
            borderRadius: "50vh",
            height: "48px",
            padding: "16px 24px",
            fontSize: "1rem",
            fontWeight: "bold",
            textTransform: "none",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
            contained: {
              color: theme.customColors.gray[100],
            },
            "& .MuiButton-startIcon path, & .MuiButton-endIcon path": {
              fill: theme.palette.getContrastText(theme.palette.primary.main),
            },
            "&.MuiButton-text .MuiButton-startIcon path, &.MuiButton-text .MuiButton-endIcon path":
              {
                fill: "unset",
              },
            "&.MuiButton-containedPrimary:hover": {
              backgroundColor: theme.customColors.primary["200"],
            },
            "&.MuiButton-containedSecondary": {
              backgroundColor: theme.customColors.complementary[400],
            },
            "&.MuiButton-containedSecondary:hover": {
              backgroundColor: theme.customColors.complementary["200"],
            },
            "&:active": {
              boxShadow: "none",
            },
            "&.Mui-disabled": {
              borderWidth: "2px",
              opacity: 0.5,
            },
            "&.MuiButton-containedPrimary.Mui-disabled": {
              color: theme.customColors.gray[100],
              backgroundColor: theme.customColors.primary["200"],
            },
            "&.MuiButton-outlinedPrimary": {
              color: theme.customColors.primary[400],
              "&:hover": {
                color: theme.customColors.primary[400],
                backgroundColor: theme.customColors.gray[150],
                borderColor: theme.customColors.gray[150],
              },
            },
            "&.MuiButton-outlinedPrimary.Mui-disabled": {
              color: theme.customColors.gray[100],
              backgroundColor: theme.customColors.primary[600],
            },
          },
          outlined: {
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: theme.customColors.gray[200],
            color: theme.customColors.primary[400],
            "&:hover": {
              color: theme.customColors.primary[400],
              backgroundColor: theme.customColors.gray[150],
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: theme.customColors.gray[150],
            },
            "&.Mui-disabled": {
              backgroundColor: theme.customColors.primary[600],
              opacity: 0.5,
            },
            "& .MuiButton-startIcon path, & .MuiButton-endIcon path": {
              fill: theme.customColors.primary[400],
            },
          },
          text: {
            color: theme.customColors.text.dark,
            "&:hover": {
              color: theme.customColors.primary[400],
            },
            "&.Mui-disabled": {
              color: theme.customColors.gray[300],
            },
          },
          textSizeSmall: {
            height: "40px",
            padding: "12px 16px",
            fontSize: "0.875rem",
            lineHeight: "1rem",
          },
          containedSizeSmall: {
            height: "40px",
            padding: "12px 16px",
            fontSize: "0.875rem",
            lineHeight: "1rem",
          },
          outlinedSizeSmall: {
            height: "40px",
            padding: "12px 16px",
            fontSize: "0.875rem",
            lineHeight: "1rem",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&.MuiIconButton-colorPrimary": {
              color: theme.palette.common.white,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused:not(.Mui-error)": {
              color: theme.customColors.gray[500],
            },
            color: theme.customColors.gray[400],
            fontSize: "0.75rem",
            fontWeight: "bold",
            lineHeight: 1,
            textTransform: "uppercase",
            transform: "none",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: "0.875rem",
            color: theme.customColors.primary,
            fontWeight: 600,
            lineHeight: 1.5,
            transition: "border-color ease-in-out 100ms",
            "&-MuiInput-root": {
              marginTop: 0,
            },
            "&.Mui-disabled > fieldset": {
              backgroundColor: theme.customColors.gray[150],
              zIndex: -1,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&:hover": {
              borderColor: theme.customColors.gray[300],
            },
            "&.Mui-focused": {
              borderColor: theme.palette.primary.main,
            },
            "&.Mui-error": {
              borderColor: theme.palette.error.main,
            },
            boxSizing: "border-box",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: theme.customColors.gray[200],
            borderRadius: "12px",
            padding: "12px 16px",
            minHeight: "48px",
          },
          input: {
            fontSize: "0.875rem",
            color: theme.customColors.text.dark,
            fontWeight: 600,
            lineHeight: 1.5,
            height: "1rem",
            padding: 0,
            "&::placeholder": {
              color: theme.customColors.gray[400],
              opacity: 1,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ":hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.customColors.gray[400],
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.customColors.gray[400],
            },
          },
          input: {
            "&.MuiSelect-select": {
              minHeight: "48px",
              boxSizing: "border-box",
              borderWidth: "2px",
              padding: "12px 48px 12px 16px",
            },
            "&::placeholder": {
              color: theme.customColors.gray[400],
              opacity: 1,
            },
          },
          notchedOutline: {
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: theme.customColors.gray[200],
            borderRadius: "12px",
            padding: "12px 16px",
            height: "unset",
            minHeight: "unset",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            border: "2px solid #E6E8EC",
            color: theme.customColors.primary[400],
          },
          icon: {
            marginLeft: "9px",
          },
          filled: {
            border: "2px solid transparent",
            backgroundColor: theme.customColors.primary["400"],
            color: theme.palette.common.white,
            "&:hover": {
              backgroundColor: theme.customColors.primary["300"],
            },
          },
        },
      },

      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: theme.customColors.gray[300],
            fontWeight: "bold",
            fontSize: "0.75rem",
            lineHeight: "0.75rem",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.customColors.gray[150],
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: "0.875rem",
            lineHeight: "1.5rem",
            color: theme.customColors.gray[400],
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0,
            marginRight: "12px",
            "&.Mui-checked": {
              color: theme.customColors.primary[400],
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            padding: "6px",
            border: `2px solid ${theme.customColors.gray[200]}`,
            borderRadius: "36px",
            width: "100%",
          },
          grouped: {
            "&:not(:last-of-type)": {
              borderTopRightRadius: "100px",
              borderBottomRightRadius: "100px",
            },
            "&:not(:first-of-type)": {
              borderTopLeftRadius: "100px",
              borderBottomLeftRadius: "100px",
              borderLeft: "none",
              marginLeft: "8px",
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            lineHeight: "1rem",
            height: "28px",
            width: "auto",
            flexGrow: 1,
            padding: "6px 12px",
            borderRadius: "100px",
            border: "none",
            "&.Mui-selected": {
              color: theme.customColors.gray[150],
              backgroundColor: theme.customColors.primary[400],
              "&:hover": {
                color: theme.customColors.gray[150],
                backgroundColor: theme.customColors.primary[400],
              },
            },
            "&:hover": {
              backgroundColor: "transparent",
              color: theme.customColors.primary[600],
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: theme.customColors.primary[300],
          },
          valueLabel: {
            backgroundColor: theme.customColors.text.dark,
            borderRadius: "8px",
            padding: "4px 8px",
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "20px",
          },
          thumb: {
            "&:hover": {
              boxShadow: "0px 0px 0px 4px rgba(255, 255, 255, 1)",
            },
            "&::before": {
              boxShadow: "none",
            },
            "&.Mui-active": {
              boxShadow: "0px 0px 0px 4px rgba(255, 255, 255, 1)",
            },
            "&.Mui-focusVisible": {
              boxShadow: "0px 0px 0px 4px rgba(255, 255, 255, 1)",
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: theme.customColors.primary[400],
            },
            "&:hover": {
              color: theme.customColors.primary[400],
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: theme.customColors.primary[200],
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: theme.customColors.primary[400],
          },
        },
      },
    },
  });
};

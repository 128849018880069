import projectReportService from "services/projectReport.service";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { IProjectReportPreset } from "types/project.service";

export const useProjectPresets = (
  projectAlias: string | null,
  config?: SWRConfiguration<IProjectReportPreset[]>
): SWRResponse<IProjectReportPreset[]> => {
  return useSWR(
    projectAlias && `Project/${projectAlias}/reports/presets`,
    () => projectReportService.getReportPresets(projectAlias!),
    config
  );
};

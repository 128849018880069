import { FeatureFlagId } from "domain/featureFlag";
import { lazy } from "react";
import { Route } from "react-router-dom";
import { RestrictedByFeatureFlagOutlet } from "ui/Router/guards/RestrictedByFeatureFlagOutlet";
import { DealDetailsWrapper } from "ui/pages/DealDetailsWrapper";
import * as paths from "ui/Router/paths";
import {
  DealsWrapper,
  DealManageNavs,
  DealPageLayout,
  DealQuestionsAndAnswers,
} from "ui/pages";
import { DealManagePageLayout } from "ui/pages/DealPage/DealManagePageLayout";
import { DealsType } from "ui/pages/DealsList/constants";

import { DealRoot } from "ui/pages";
import { CampaignsWrapper } from "ui/pages/Campaigns/CampaignsWrapper";

import { RestrictedByGroupOutlet } from "ui/Router/guards/RestrictedByGroupOutlet";

import { DealEditPageType } from "ui/pages/Form";
import { CampaignsDealBreadcrumbsLayout } from "ui/pages/Campaigns/CampaignsDealBreadcrumbsLayout";
import DealCreateCampaign from "ui/pages/DealCreateCampaign/DealCreateCampaign";

const DealRedirectById = lazy(() => import("ui/pages/DealRedirectById"));
const DealDetail = lazy(
  () => import("ui/pages/DealPage/DealDetail/DealDetail")
);

const DealDataRoom = lazy(() => import("ui/pages/DealDataRoom"));
const DealDataRoomUserPermissions = lazy(
  () => import("ui/pages/DealDataRoomPermissions")
);

const DealAssistant = lazy(() => import("ui/pages/DealAssistant"));
const DealReports = lazy(() => import("ui/pages/DealReports"));
const DealDataRoomPerformance = lazy(
  () => import("ui/pages/DealReports/DealReports.dataRoom")
);
const DealDataPerformance = lazy(
  () => import("ui/pages/DealReports/DealReports.deal")
);
const DealDashboard = lazy(() => import("ui/pages/DealDashboard"));
const DealsList = lazy(() => import("ui/pages/DealsList/DealsList"));

const CampaignsContactList = lazy(
  () => import("ui/pages/CampaignsContactList")
);
const CampaignsContactLists = lazy(
  () => import("ui/pages/CampaignsContactLists")
);

const DealExclusionList = lazy(() => import("ui/pages/DealExclusionList"));

const DealCreate = lazy(() => import("ui/pages/DealCreate/DealCreate"));
const DealAssistantCreate = lazy(() => import("ui/pages/DealAssistantCreate"));
const DealAssistantGenerating = lazy(
  () => import("ui/pages/DealAssistantCreate/DealAssistantCreate.generating")
);
const DealUpdate = lazy(() => import("ui/pages/DealCreate/DealCreate.update"));
const DealEditHistoricalPerformance = lazy(
  () => import("ui/pages/DealEditHistoricalPerformance")
);
const DealEditPersonLeadInvestor = lazy(
  () => import("ui/pages/DealEditPersonLeadInvestor")
);
const DealEditPersonSponsor = lazy(
  () => import("ui/pages/DealEditPersonSponsor")
);
const DealEditPersonAdvisor = lazy(
  () => import("ui/pages/DealEditPersonAdvisor")
);
const DealEditKeyBiographies = lazy(
  () => import("ui/pages/DealEditKeyBiographies")
);
const DealEditKeyHighlights = lazy(
  () => import("ui/pages/DealEditKeyHighlights")
);
const DealEditSummary = lazy(() => import("ui/pages/DealEditSummary"));

const DealEditPodcast = lazy(
  () => import("ui/pages/DealEditPodcast/DealEditPodcast")
);
const DealEditPodcastTranscription = lazy(
  () => import("ui/pages/DealEditPodcast/DealEditPodcast.transcription")
);
const DealEditPodcastReview = lazy(
  () => import("ui/pages/DealEditPodcast/DealEditPodcast.review")
);

const DealCampaigns = lazy(
  () => import("ui/pages/DealCampaigns/DealCampaigns")
);
const DealEditCampaign = lazy(
  () => import("ui/pages/DealEditCampaign/DealEditCampaign")
);

const DealEditCampaignReview = lazy(
  () => import("ui/pages/DealEditCampaign/DealEditCampaign.review")
);

const DealRolePermission = lazy(
  () => import("ui/pages/DealRolePermissions/DealRolePermissions")
);

const DealUserPermissions = lazy(
  () => import("ui/pages/DealUserPermissions/DealUserPermissions")
);

const Portfolio = lazy(() => import("ui/pages/Portfolio/Portfolio"));

export const dealsRoutes = [
  <Route
    key="dealsRoutes"
    element={
      <RestrictedByFeatureFlagOutlet featureFlag={FeatureFlagId.Deals} />
    }
  >
    <Route path={paths.deals} element={<DealsWrapper />}>
      <Route path="all" element={<DealsList dealsType={DealsType.ALL} />} />
      <Route path="your" element={<DealsList dealsType={DealsType.MY} />} />
    </Route>

    <Route path="deal/redirect/:id" element={<DealRedirectById />} />
    <Route path="deal/:alias" element={<DealDetailsWrapper />}>
      <Route element={<DealPageLayout />}>
        <Route index element={<DealDetail />} />

        <Route path="manage" element={<DealManageNavs />} />

        <Route
          path="questions-and-answers"
          element={<DealQuestionsAndAnswers />}
        />
        <Route path="reports" element={<DealReports />} />
      </Route>
      <Route path="report/" element={<DealManagePageLayout />}>
        <Route
          path="data-room-performance"
          element={<DealDataRoomPerformance />}
        />
        <Route path="deal-performance" element={<DealDataPerformance />} />
      </Route>

      <Route element={<DealManagePageLayout />}>
        <Route path="dashboard" element={<DealDashboard />} />
      </Route>

      <Route path="assistant" element={<DealAssistant />} />

      <Route path="dataroom/vdr" element={<DealDataRoom />}>
        <Route path="*" element={<DealDataRoom />} />
      </Route>
    </Route>

    <Route
      path={paths.dealDataroomUserPermissions}
      element={<DealDataRoomUserPermissions />}
    />
    <Route path={paths.portfolioPath} element={<Portfolio />} />
    <Route path="deal" element={<DealRoot />}>
      <Route
        path="create"
        element={<RestrictedByGroupOutlet fundManager syndicationAdmin />}
      >
        <Route
          index
          element={
            <DealCreate routeAfterSubmit={"/" + paths.createDealLeadInvestor} />
          }
        />
        <Route path="ai" element={<DealAssistantCreate />} />
      </Route>
      <Route path=":alias">
        <Route
          path="create"
          element={<RestrictedByGroupOutlet fundManager syndicationAdmin />}
        >
          <Route
            path="lead-investor"
            element={
              <DealEditPersonLeadInvestor
                routeAfterSubmit={"/" + paths.createDealSponsor}
                type={DealEditPageType.Create}
              />
            }
          />
          <Route
            path="sponsor"
            element={
              <DealEditPersonSponsor
                routeAfterSubmit={"/" + paths.createDealAdvisor}
                type={DealEditPageType.Create}
              />
            }
          />
          <Route
            path="advisor"
            element={
              <DealEditPersonAdvisor
                routeAfterSubmit={"/" + paths.createDealHistoricalPerformance}
                type={DealEditPageType.Create}
              />
            }
          />
          <Route
            path="historical-performance"
            element={
              <DealEditHistoricalPerformance
                routeAfterSubmit={"/" + paths.createDealKeyBiographies}
                type={DealEditPageType.Create}
              />
            }
          />
          <Route
            path="key-biographies"
            element={
              <DealEditKeyBiographies
                routeAfterSubmit={"/" + paths.createDealKeyHighlights}
                type={DealEditPageType.Create}
              />
            }
          />
          <Route
            path="key-highlights"
            element={
              <DealEditKeyHighlights
                routeAfterSubmit={"/" + paths.createDealSummary}
                type={DealEditPageType.Create}
              />
            }
          />
          <Route
            path="summary"
            element={
              <DealEditSummary
                type={DealEditPageType.Create}
                routeAfterSubmit={"/" + paths.dealDetails}
              />
            }
          />
        </Route>
        <Route path="generating/ai" element={<DealAssistantGenerating />} />
        <Route path="edit/">
          <Route index element={<DealUpdate />} />
          <Route
            path="lead-investor"
            element={
              <DealEditPersonLeadInvestor type={DealEditPageType.Edit} />
            }
          />
          <Route
            path="sponsor"
            element={<DealEditPersonSponsor type={DealEditPageType.Edit} />}
          />
          <Route
            path="advisor"
            element={<DealEditPersonAdvisor type={DealEditPageType.Edit} />}
          />
          <Route
            path="historical-performance"
            element={
              <DealEditHistoricalPerformance type={DealEditPageType.Edit} />
            }
          />
          <Route
            path="key-biographies"
            element={<DealEditKeyBiographies type={DealEditPageType.Edit} />}
          />
          <Route
            path="key-highlights"
            element={<DealEditKeyHighlights type={DealEditPageType.Edit} />}
          />
          <Route
            path="summary"
            element={<DealEditSummary type={DealEditPageType.Edit} />}
          />
          <Route path="podcast">
            <Route index element={<DealEditPodcast />} />
            <Route path=":mediaId">
              <Route
                path="transcription"
                element={<DealEditPodcastTranscription />}
              />
              <Route path="review" element={<DealEditPodcastReview />} />
            </Route>
          </Route>
          <Route path="campaign">
            <Route element={<CampaignsDealBreadcrumbsLayout />}>
              <Route index element={<DealCreateCampaign />} />
              <Route path=":campaignId">
                <Route index element={<DealEditCampaign />} />
                <Route path="summary" element={<DealEditCampaignReview />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="role-permissions" element={<DealRolePermission />} />
        <Route path="user-permissions" element={<DealUserPermissions />} />
        <Route path="exclusion-lists" element={<DealExclusionList />} />
        <Route path="campaign/">
          <Route element={<CampaignsWrapper />}>
            <Route path="regular" element={<DealCampaigns />} />
            <Route path="contact-lists" element={<CampaignsContactLists />} />
          </Route>
          <Route path="contact-list">
            <Route path=":id" element={<CampaignsContactList />} />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>,
];

import { isBullpen } from "application/platformConfiguration";

export const defaultDisclosure = (syndicationPartnerAlias = "Emerge9") => {
  if (isBullpen()) {
    return `This site is operated by Emerge9, Inc., (“Emerge9"), a Delaware corporation.
  Bullpen is registered trademark of Emerge9. Bullpen evolved from the need to drive 
  efficiency and accuracy in the production of deal documentation at Emerge9. 
  Bullpen has powered $22 Bn in Emerge9 deals. 
  Emerge9 has been offering co-investments with top institutions offered since 2022. 
  For more information visit Emerge9.com.`;
  }

  const intro =
    syndicationPartnerAlias === "Emerge9"
      ? `This site is operated by Emerge9, Inc. ("Emerge9"), Delaware
  corporation.`
      : "";
  const disclosure = `${syndicationPartnerAlias} does not make investment recommendations for
  any securities listed on this website or other company
  communications. Alternative investments in private assets funds,
  direct securities or otherwise are generally illiquid and involve
  significant risk. There is no guarantee investors will recoup their
  investment and the entirety of the principal is at risk. It is the
  responsibility of any investor to carefully review relevant deal
  documents and consider all associated risks, costs, and expenses
  before making an investment. Private asset valuations reflect
  restricted membership interest in underlying assets that are
  typically subject to holding periods with limited liquidity access,
  typically for 5 years or more.`;

  return `${intro} ${disclosure}`.trim();
};

import React, { forwardRef } from "react";
import CheckboxMui, { CheckboxProps } from "@mui/material/Checkbox";
import UncheckedIcon from "./UncheckedIcon";
import CheckedIcon from "./CheckedIcon";
import { useTheme } from "@mui/material";

const Checkbox = forwardRef<
  HTMLButtonElement,
  CheckboxProps & React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const { customColors } = useTheme();

  return (
    <CheckboxMui
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedIcon />}
      disableRipple
      {...props}
      ref={ref}
      sx={{
        "&.Mui-checked": {
          color: customColors.primary[400],
        },
      }}
    />
  );
});

export default Checkbox;

import { findIndex } from "lodash";
import { DragEvent, useEffect, useState } from "react";
import { IReorderContentPayload } from "types";

export interface IUseDraggableList<T extends { id: string }> {
  originalSource?: T[];
  onRearrange?: (
    rearrangedItem: T,
    reorderContent: IReorderContentPayload
  ) => Promise<void>;
  getDragIndicators: () => HTMLElement[];
}

export const useDraggableList = <T extends { id: string }>({
  originalSource,
  onRearrange,
  getDragIndicators,
}: IUseDraggableList<T>) => {
  const [savedItems, setSavedItems] = useState<T[]>(originalSource || []);

  useEffect(() => {
    if (!originalSource) return;

    setSavedItems(originalSource);
  }, [originalSource]);

  const onDrop = async (e: DragEvent<HTMLDivElement>) => {
    const itemId = e.dataTransfer.getData("dragItemId");
    clearHighlights();

    if (!itemId) return;

    const indicators = getDragIndicators();
    const { element } = getNearestIndicator(e, indicators);

    const before = element.dataset.before || "-1";

    if (before === itemId) return;

    const copy = [...savedItems];

    const itemToMove = copy.find((c) => c.id === itemId);

    if (!itemToMove) return;

    const listWithoutCurrentItem = copy.filter((c) => c.id !== itemId);

    const moveToEnd = before === "-1";

    const insertAtIndex = moveToEnd
      ? listWithoutCurrentItem.length
      : findIndex(listWithoutCurrentItem, { id: before as any });

    if (insertAtIndex < 0) return;

    const newListValue = [
      ...listWithoutCurrentItem.slice(0, insertAtIndex),
      itemToMove,
      ...listWithoutCurrentItem.slice(insertAtIndex),
    ];

    setSavedItems(newListValue);

    const { nextIndex, previousIndex } = {
      previousIndex: insertAtIndex - 1,
      nextIndex: insertAtIndex + 1,
    };

    await onRearrange?.(itemToMove, {
      previousId:
        previousIndex < 0 ? undefined : newListValue[previousIndex]?.id,
      nextId: newListValue?.[nextIndex]?.id,
    });
  };

  const onDragStart = (e: DragEvent<HTMLDivElement>, item: T) => {
    e.dataTransfer.setData("dragItemId", item.id);
  };

  const clearHighlights = (els?: HTMLElement[]) => {
    const indicators = els || getDragIndicators();

    indicators.forEach((i) => {
      (i as any).style.opacity = "0";
    });
  };

  const getNearestIndicator = (
    e: DragEvent<HTMLDivElement>,
    indicators: HTMLElement[]
  ) => {
    const DISTANCE_OFFSET = 50;

    const el = indicators.reduce(
      (closest, child) => {
        const box = child.getBoundingClientRect();

        const offset = e.clientY - (box.top + DISTANCE_OFFSET);

        if (offset < 0 && offset > closest.offset) {
          return { offset: offset, element: child };
        } else {
          return closest;
        }
      },
      {
        offset: Number.NEGATIVE_INFINITY,
        element: indicators[indicators.length - 1],
      }
    );

    return el;
  };

  const highlightIndicator = (e: DragEvent<HTMLDivElement>) => {
    const indicators = getDragIndicators();
    clearHighlights(indicators);

    const el = getNearestIndicator(e, indicators);

    (el as any).element.style.opacity = "1";
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    highlightIndicator(e);
  };

  return {
    onDragOver,
    onDragStart,
    onDrop,
    savedItems,
  };
};

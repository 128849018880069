import { isBullpen } from "application/platformConfiguration";
import { BullpenAppRouter } from "bullpen/router/BullpenAppRouter";
import EmergeRouter from "./EmergeRouter";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "redux/User/actions";
import authService from "services/auth.service";
import { useNotifier } from "ui/hooks";
import { useTypedSelector } from "ui/hooks/useTypeSelector";
import { CognitoUser } from "redux/Auth/types";

const AppRouter = ({ hasLayoutError }: { hasLayoutError: boolean }) => {
  const dispatch = useDispatch();
  useNotifier();
  const { user } = useTypedSelector((state) => state.auth);
  const [isInitialized, setInitialized] = useState(false);

  const initializeAuthToken = useCallback((currentUser: CognitoUser | null) => {
    if (!currentUser) return authService.updateApiToken(undefined);

    return authService.updateApiToken(
      currentUser?.signInUserSession?.idToken?.jwtToken
    );
  }, []);

  const initialize = useCallback(async () => {
    initializeAuthToken(user);

    if (user) dispatch(fetchUserProfile());

    setInitialized(true);
  }, [dispatch, initializeAuthToken, user]);

  useEffect(() => {
    if (isInitialized) return;

    initialize();
  }, [initialize, isInitialized]);

  if (!isInitialized) {
    return null;
  }

  if (isBullpen()) {
    return <BullpenAppRouter hasLayoutError={hasLayoutError} />;
  }

  return <EmergeRouter hasLayoutError={hasLayoutError} />;
};

export default AppRouter;

import * as React from "react";
import type { SVGProps } from "react";
const SvgHandshake = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill="#CCB502"
      d="M15.815 3.187a4 4 0 0 1 4.366-.046l5.54 3.526h2.28c.735 0 1.332.597 1.332 1.333v12c0 .736-.596 1.333-1.333 1.333h-2.026A3.53 3.53 0 0 1 24.26 24.6l-6.791 4.074a2.67 2.67 0 0 1-2.761-.01 2.67 2.67 0 0 1-3.414.098l-6.854-5.386a2.67 2.67 0 0 1-.753-3.262 2.67 2.67 0 0 1-1.021-2.098V8c0-.736.597-1.333 1.333-1.333h6.596zM5.564 18.194l.923-.808a4 4 0 0 1 5.593.32l3.603 3.963a4 4 0 0 1 .528 4.65l6.678-4.007a.876.876 0 0 0 .371-1.054l-5.903-8.264a1.33 1.33 0 0 0-1.468-.502l-3.313.994a4 4 0 0 1-3.978-1.003l-.39-.39a2.66 2.66 0 0 1-.635-2.76h-2.24v8.682zM18.75 5.39a1.33 1.33 0 0 0-1.456.015l-7.201 4.801.39.39c.347.347.856.476 1.326.335l3.314-.994a4 4 0 0 1 4.404 1.506l5.16 7.222h1.98V9.333h-.945a2.67 2.67 0 0 1-1.432-.417zM8.243 19.393l-2.155 1.885 6.855 5.386.938-1.643a1.33 1.33 0 0 0-.17-1.558L10.106 19.5a1.333 1.333 0 0 0-1.864-.107"
    />
  </svg>
);
export default SvgHandshake;

import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { DEFAULT_PAGE } from "domain/seo";
import sanitizeHtml from "sanitize-html";
import { useSyndicationLayout } from "ui/hooks";
import { platformConfiguration } from "application/platformConfiguration";

interface SEOHelmetProps {
  title?: string;
  description?: string;
  keywords?: string;
  isIndexable?: boolean;
  isBase?: boolean;
  includeCanonical?: boolean;
}

const SEOHelmet = ({
  title = DEFAULT_PAGE.title,
  description = DEFAULT_PAGE.description,
  keywords,
  isIndexable,
  isBase,
  includeCanonical,
}: SEOHelmetProps) => {
  const layout = useSyndicationLayout();

  const canonicalValue = useMemo(() => {
    return window.location.origin + window.location.pathname;
  }, []);

  const titleValue = useMemo(() => {
    const sanitizedTitle = sanitizeHtml(title, { allowedTags: [] });

    if (!sanitizedTitle) return "";

    const titleWithSuffix = isBase
      ? sanitizedTitle + ` - ${DEFAULT_PAGE.title}`
      : `${sanitizedTitle} | ${platformConfiguration.displayName}`;

    if (layout.isSyndicate) {
      return titleWithSuffix.replace("Emerge9", layout.mainInfo.shortName);
    }

    return titleWithSuffix;
  }, [isBase, layout, title]);

  const descriptionValue = useMemo(() => {
    const sanitizedDescription = sanitizeHtml(description, {
      allowedTags: [],
    });

    if (layout.isSyndicate) {
      return sanitizedDescription.replace(
        /Emerge9/gi,
        layout.mainInfo.shortName
      );
    }

    return sanitizedDescription;
  }, [layout, description]);

  return (
    <Helmet>
      {titleValue && [
        <title key={0}>{titleValue}</title>,
        <meta key={1} property="og:title" content={titleValue} />,
        <meta key={2} property="twitter:title" content={titleValue} />,
      ]}
      {descriptionValue && [
        <meta key={0} name="description" content={descriptionValue} />,
        <meta key={1} name="og:description" content={descriptionValue} />,
        <meta key={2} name="twitter:description" content={descriptionValue} />,
      ]}
      {includeCanonical && <link rel="canonical" href={canonicalValue} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {!isIndexable && <meta name="robots" content="index, follow" />}
    </Helmet>
  );
};

export default SEOHelmet;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITextRange } from "domain/common";
import {
  IAddTemplateModalParams,
  IEditReportModuleModalParams,
  IEditTemplateSchemaModalParams,
  IEditTemplateSectionModalParams,
  IExportReportModalParams,
  IRenameModalParams,
} from "ui/pages/ProjectPage/ProjectReports/ProjectReports.types";

export interface ProjectReportsState {
  isMenuCollapsed: boolean;
  isChatBarCollapsed: boolean;
  chatBarPayload: {
    moduleId: string;
    selection?: { range: ITextRange; text: string };
  } | null;
  addTemplateModalParams: IAddTemplateModalParams;
  editTemplateSectionModalParams: IEditTemplateSectionModalParams;
  editTemplateSchemaModalParams: IEditTemplateSchemaModalParams;
  exportReportModalParams: IExportReportModalParams;
  editReportModuleModalParams: IEditReportModuleModalParams;
  createReportModalParams:
    | {
        isOpen: true;
        presetId: string;
      }
    | { isOpen: false; presetId?: never };
  renameModalParams: IRenameModalParams;
  isCreateTemplateModalOpen: boolean;
}

const initialState: ProjectReportsState = {
  chatBarPayload: null,
  isChatBarCollapsed: true,
  isMenuCollapsed: false,
  addTemplateModalParams: { isOpen: false },
  editTemplateSectionModalParams: { isOpen: false },
  editTemplateSchemaModalParams: { isOpen: false },
  exportReportModalParams: { isOpen: false },
  createReportModalParams: { isOpen: false },
  editReportModuleModalParams: { isOpen: false },
  renameModalParams: { isOpen: false, type: "notSelected", initialValue: "" },
  isCreateTemplateModalOpen: false,
};

const projectReportsSlice = createSlice({
  name: "projectReports",
  initialState,
  reducers: {
    openChatBar(
      state: ProjectReportsState,
      { payload }: PayloadAction<ProjectReportsState["chatBarPayload"]>
    ) {
      state.isChatBarCollapsed = false;
      state.isMenuCollapsed = true;

      state.chatBarPayload = payload;
    },
    toggleMenu(
      state: ProjectReportsState,
      { payload }: PayloadAction<boolean | undefined>
    ) {
      if (typeof payload === "boolean") {
        state.isMenuCollapsed = !payload;
        return;
      }

      state.isMenuCollapsed = !state.isMenuCollapsed;
    },
    toggleChatBar(
      state: ProjectReportsState,
      { payload }: PayloadAction<boolean | undefined>
    ) {
      if (typeof payload === "boolean") {
        state.isChatBarCollapsed = !payload;
        return;
      }

      state.isChatBarCollapsed = !state.isChatBarCollapsed;
    },
    setAddTemplateModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IAddTemplateModalParams>
    ) {
      state.addTemplateModalParams = payload;
    },
    setEditTemplateSectionModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IEditTemplateSectionModalParams>
    ) {
      state.editTemplateSectionModalParams = payload;
    },
    setEditTemplateSchemaModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IEditTemplateSchemaModalParams>
    ) {
      state.editTemplateSchemaModalParams = payload;
    },
    setExportReportModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IExportReportModalParams>
    ) {
      state.exportReportModalParams = payload;
    },
    setCreateReportModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<ProjectReportsState["createReportModalParams"]>
    ) {
      state.createReportModalParams = payload;
    },
    setRenameModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IRenameModalParams>
    ) {
      state.renameModalParams = payload;
    },
    setCreateTemplateModalOpen(
      state: ProjectReportsState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isCreateTemplateModalOpen = payload;
    },
    setEditReportModuleModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IEditReportModuleModalParams>
    ) {
      state.editReportModuleModalParams = payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  openChatBar,
  toggleChatBar,
  toggleMenu,
  setAddTemplateModalParams,
  setEditTemplateSchemaModalParams,
  setEditTemplateSectionModalParams,
  setExportReportModalParams,
  setCreateReportModalParams,
  setRenameModalParams,
  setCreateTemplateModalOpen,
  setEditReportModuleModalParams,
  reset,
} = projectReportsSlice.actions;

export default projectReportsSlice.reducer;

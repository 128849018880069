import {
  Add,
  AdminPanelSettings,
  Apps,
  Bookmark,
  Diamond,
  Loupe,
  MailOutline,
  Timeline,
  Folder,
} from "@mui/icons-material";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { FC, ReactElement, Suspense, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import * as paths from "ui/Router/paths";
import Aside, { IAsideItem } from "ui/components/Aside/Aside";
import NavigationDrawer from "ui/components/NavigationDrawer/NavigationDrawer";
import { useGroupValidation, useSyndicationLayout } from "ui/hooks";
import { ISearchInputs } from "./AdminManage.components";
import "./style.scss";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";
import { config } from "config";
import { isBullpen } from "application/platformConfiguration";
import { excludeItemsWhenBullpen } from "bullpen/utils/excludeItemsWhenBullpen";

interface SubPageItem extends IAsideItem {
  pageHeader?: ReactElement;
}

export enum AdminPageName {
  Users,
  Deals,
  Preferences,
  DeveloperActions,
  Partners,
  SyndicationUsers,
  SyndicationCampaigns,
  InviteCodeList,
  Projects,
}

const excludeWhenBullpenList = [
  AdminPageName.Deals,
  AdminPageName.InviteCodeList,
  AdminPageName.Partners,
  AdminPageName.SyndicationUsers,
  AdminPageName.SyndicationCampaigns,
];

const excludeWhenBullpen = excludeItemsWhenBullpen<AdminPageName>(
  excludeWhenBullpenList
);

const AdminManageWrapper: FC = () => {
  const { isSyndicate } = useSyndicationLayout();
  const { isDeveloper, isAdmin } = useGroupValidation();
  const { isDealsOn, isProjectsOn } = useFeatureFlag();
  const location = useLocation();
  const completePathname = [location.pathname, location.hash].join("");
  const [searchParams] = useSearchParams();
  const form = useForm<ISearchInputs>({
    defaultValues: {
      platform: null,
      filter: "",
      overridePlatformId: searchParams.get("platform") || undefined,
    },
  });

  const navItemsMap = useMemo<Record<AdminPageName, SubPageItem>>(
    () => ({
      [AdminPageName.Users]: {
        label: "Users",
        to: paths.manageUsersList,
        Icon: Bookmark,
      },
      [AdminPageName.Deals]: {
        label: "Deals",
        to: paths.manageDeals,
        Icon: Apps,
      },
      [AdminPageName.Projects]: {
        label: "Projects",
        to: paths.manageProjects,
        Icon: Folder,
      },
      [AdminPageName.Preferences]: {
        label: "Preferences",
        to: paths.manageFlags,
        Icon: Diamond,
      },
      [AdminPageName.DeveloperActions]: {
        label: "Developer actions",
        to: paths.manageDeveloperActions,
        Icon: AdminPanelSettings,
      },
      [AdminPageName.Partners]: {
        label: "Syndication partners",
        Icon: Timeline,
        to: paths.managePartners,
        pageHeader: !isSyndicate ? (
          <Link to={"/" + paths.managePartnerCreate}>
            <Button
              className="hidden md:flex"
              startIcon={<Add />}
              variant="contained"
            >
              Add a partner
            </Button>
            <IconButton
              className="flex md:hidden bg-primary justify-center ml-8 items-center hover:bg-primary-300 h-12 w-12 text-gray-100"
              color="primary"
            >
              <Add />
            </IconButton>
          </Link>
        ) : undefined,
      },
      [AdminPageName.SyndicationUsers]: {
        label: !isSyndicate ? "Syndication partner users" : "Users",
        to: paths.manageSyndicationUsers,
        Icon: !isSyndicate ? Loupe : Bookmark,
      },
      [AdminPageName.SyndicationCampaigns]: {
        label: "Syndication partner campaigns",
        to: paths.manageSyndicationCampaigns,
        Icon: () => (
          <div className="text-gray-400">
            <EmailOutlinedIcon style={{ width: 20 }} />
          </div>
        ),
      },
      [AdminPageName.InviteCodeList]: {
        label: "Invitation codes",
        Icon: MailOutline,
        to: paths.manageInviteList,
      },
    }),
    [isSyndicate]
  );

  const navItemsMain = useMemo<AdminPageName[]>(() => {
    const devItems = isDeveloper ? [AdminPageName.DeveloperActions] : [];

    return [
      AdminPageName.Users,
      ...(isDealsOn ? [AdminPageName.Deals] : []),
      ...(isProjectsOn && (config.env === "prod" ? isDeveloper : isAdmin)
        ? [AdminPageName.Projects]
        : []),
      AdminPageName.InviteCodeList,
      AdminPageName.Preferences,
      ...devItems,
    ];
  }, [isDeveloper, isDealsOn, isProjectsOn, isAdmin])
    .filter(excludeWhenBullpen)
    .map((item) => navItemsMap[item]);

  const navItemsSyndicate = useMemo<AdminPageName[]>(() => {
    if (!isSyndicate) {
      return [
        AdminPageName.Partners,
        AdminPageName.SyndicationUsers,
        AdminPageName.SyndicationCampaigns,
      ];
    }

    return [
      AdminPageName.SyndicationUsers,
      ...(isDealsOn ? [AdminPageName.Deals] : []),
      ...(isProjectsOn &&
      isDeveloper &&
      (config.env === "prod" ? isDeveloper : isAdmin)
        ? [AdminPageName.Projects]
        : []),
      AdminPageName.InviteCodeList,
      AdminPageName.Preferences,
    ];
  }, [isSyndicate, isDealsOn, isProjectsOn, isDeveloper, isAdmin])
    .filter(excludeWhenBullpen)
    .map((item) => navItemsMap[item]);

  const drawerItems = useMemo(() => {
    if (!isSyndicate) {
      return [...navItemsMain, ...navItemsSyndicate].map((item) => ({
        ...item,
        to: "/" + item.to,
      }));
    }

    return navItemsSyndicate.map((item) => ({
      ...item,
      to: "/" + item.to,
    }));
  }, [isSyndicate, navItemsMain, navItemsSyndicate]);

  const activeSubItem = useMemo(
    () =>
      [...navItemsMain, ...navItemsSyndicate].find(
        (item) => "/" + item.to === completePathname
      ),
    [completePathname, navItemsMain, navItemsSyndicate]
  );

  return (
    <div className="w-full">
      <NavigationDrawer
        className="block md:hidden"
        title="Manage user"
        items={drawerItems}
      />
      <div className="m9-container">
        <div className="flex justify-between items-center my-7">
          <Typography variant="h2" className="text-primary-600 font-serif">
            Admin management
          </Typography>
          {activeSubItem?.pageHeader && <div>{activeSubItem?.pageHeader}</div>}
        </div>
        <Grid container spacing={4}>
          <Grid item xs={0} md={3} className="hidden md:block">
            {!isSyndicate && (
              <Aside
                asideItems={navItemsMain}
                title={isBullpen() ? "Bullpen Management" : "E9 Management"}
              />
            )}
            <Aside
              asideItems={navItemsSyndicate}
              title={isSyndicate ? "Syndication Management" : undefined}
            />
          </Grid>
          <Grid item xs={12} md={9} className="md:col-span-9">
            <Suspense fallback={<></>}>
              <FormProvider {...form}>
                <Outlet />
              </FormProvider>
            </Suspense>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AdminManageWrapper;

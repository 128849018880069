import { lazy } from "react";
import { Route } from "react-router-dom";
import * as paths from "ui/Router/paths";
import { FeatureFlagId } from "domain/featureFlag";
import { RestrictedByFeatureFlagOutlet } from "ui/Router/guards/RestrictedByFeatureFlagOutlet";
import { ProjectPageWrapper } from "ui/pages/ProjectPage/ProjectPage.wrapper";
import { ProjectGuard } from "ui/Router/guards/ProjectGuard";

const ProjectList = lazy(() => import("ui/pages/ProjectList"));
const ProjectCreate = lazy(() => import("ui/pages/ProjectCreate"));
const ProjectDataRoom = lazy(
  () => import("ui/pages/ProjectPage/ProjectDataRoom")
);
const ProjectAssistant = lazy(
  () => import("ui/pages/ProjectPage/ProjectAssistant")
);
const ProjectDataRoomPermissions = lazy(
  () => import("ui/pages/ProjectDataRoomPermissions")
);
const ProjectSettings = lazy(
  () => import("ui/pages/ProjectPage/ProjectSettings")
);
const ProjectReports = lazy(
  () => import("ui/pages/ProjectPage/ProjectReports")
);

const ProjectManage = lazy(() => import("ui/pages/ProjectPage/ProjectManage"));

export const bullpenProjectsRoutes = [
  <Route
    key="projectsRoutes"
    element={
      <RestrictedByFeatureFlagOutlet featureFlag={FeatureFlagId.Projects} />
    }
  >
    <Route path={paths.projectList} element={<ProjectList />} />
    <Route path={paths.projectCreate} element={<ProjectCreate />} />

    <Route path="project/:alias" element={<ProjectPageWrapper />}>
      <Route path="dataroom/vdr">
        <Route index element={<ProjectDataRoom />} />
        <Route path="*" element={<ProjectDataRoom />} />
      </Route>
      <Route path="assistant" element={<ProjectAssistant />} />
      <Route path="reports" element={<ProjectReports />} />
      <Route element={<ProjectGuard checkPermission={(p) => p.canManage} />}>
        <Route path="manage" element={<ProjectManage />} />
      </Route>
      <Route element={<ProjectGuard checkPermission={(p) => p.canManage} />}>
        <Route path="settings" element={<ProjectSettings />} />
      </Route>
    </Route>
    <Route element={<ProjectGuard checkPermission={(p) => p.canManage} />}>
      <Route
        path={paths.projectDataroomPermissions}
        element={<ProjectDataRoomPermissions />}
      />
    </Route>
  </Route>,
];

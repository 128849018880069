import { Link } from "react-router-dom";
import { clsxm } from "application/utils";
import { ClassValue } from "clsx";
import { PropsWithChildren, ReactNode } from "react";
import styles from "./styles.module.scss";

interface BullpenButtonProps extends PropsWithChildren {
  variant?: "primary" | "secondary" | "tertiary";
  href?: string;
  className?: string;
  disabled?: boolean;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  iconClassName?: string;
}

const twStyles: {
  [key: string]: (disabled?: boolean) => ClassValue[];
} = {
  primary: (disabled?: boolean) => [
    "rounded-full border-solid border-[3px] border-bullpen-primary-500 cursor-pointer",
    "px-[33px] py-[13px]",
    "inline-flex items-center justify-center",
    "text-bullpen-primary-950 text-center font-poppins font-semibold text-sm leading-[14px]",
    "bg-bullpen-primary-300",
    "hover:bg-bullpen-primary-900 hover:text-bullpen-primary-50 hover:border-bullpen-primary-950",
    "focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-bullpen-primary-300 focus:ring-opacity-20",
    styles.primary,
    ...(disabled
      ? [
          "cursor-not-allowed text-gray-400 hover:text-gray-400",
          "bg-gray-150 hover:bg-gray-150",
          styles.primaryDisabled,
        ]
      : []),
  ],
  secondary: (disabled?: boolean) => [
    "bg-white",
    "border-solid border-[3px] border-bullpen-secondary-900 rounded-full",
    "px-[33px] py-[10px]",
    "inline-flex items-center justify-center",
    "text-bullpen-primary-950 text-center font-poppins font-semibold text-sm leading-[14px]",
    "hover:text-bullpen-primary-300 hover:bg-bullpen-secondary-900",
    "focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-bullpen-secondary-900 focus:ring-opacity-10",
    ...(disabled
      ? [
          "cursor-not-allowed text-gray-400 hover:text-gray-400",
          "border-solid border-[3px] border-gray-150",
          "bg-white hover:bg-white",
        ]
      : []),
  ],
  tertiary: (disabled?: boolean) => [
    "bg-transparent",
    "border-solid border-[3px] border-white rounded-full",
    "px-[33px] py-[10px]",
    "inline-flex items-center justify-center",
    "stroke-current text-white text-center font-poppins font-semibold text-sm leading-[14px]",
    "hover:bg-white hover:text-bullpen-secondary-950",
    "focus:bg-white focus:text-white focus:bg-opacity-10 focus:outline-0 focus:ring-0 focus:ring-offset-0 focus:ring-none",
    ...(disabled
      ? [
          "cursor-not-allowed text-white text-opacity-40 hover:text-white hover:text-opacity-40",
          "bg-white bg-opacity-10 py-[13px] border-none hover:bg-white hover:bg-opacity-10",
        ]
      : []),
  ],
};

const getStyles = (variant: string, disabled: boolean, className?: string) =>
  clsxm(...twStyles[variant](disabled), className);

export const BullpenButton = ({
  variant = "primary",
  href,
  disabled = false,
  endIcon,
  startIcon,
  className,
  iconClassName,
  children,
}: BullpenButtonProps) => {
  const content = (
    <>
      {startIcon && (
        <span className={clsxm("pr-4", iconClassName)}>{startIcon}</span>
      )}
      {children}
      {endIcon && (
        <span className={clsxm("pl-4 flex items-center", iconClassName)}>
          {endIcon}
        </span>
      )}
    </>
  );

  return href ? (
    <Link to={href} className={getStyles(variant, disabled, className)}>
      {content}
    </Link>
  ) : (
    <button
      type="submit"
      disabled={disabled}
      className={getStyles(variant, disabled, className)}
    >
      {content}
    </button>
  );
};

import { FC, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Loading } from "ui/components";
import SEOHelmet from "ui/components/SEOHelmet/SEOHelmet";
import { useLocationRedirects, useOnboardingSteps } from "ui/hooks";
import { useTypedSelector } from "ui/hooks/useTypeSelector";
import * as paths from "ui/Router/paths";

interface AuthorizedOutletProps {
  dismissRedirect?: boolean;
}

export const AuthorizedOutlet: FC<AuthorizedOutletProps> = ({
  dismissRedirect,
}) => {
  const { authorized } = useTypedSelector((state) => state.auth);
  const { data: onboardingSteps, isValidating } = useOnboardingSteps();

  const location = useLocation();
  const { isLoading, checkAndRedirectToStep } = useLocationRedirects();

  useEffect(() => {
    const redirect = async () => {
      if (!dismissRedirect && !isValidating) {
        await checkAndRedirectToStep(onboardingSteps?.response);
      }
    };

    redirect();
  }, [checkAndRedirectToStep, dismissRedirect, onboardingSteps, isValidating]);

  if (!dismissRedirect && isLoading) {
    return <Loading full />;
  }

  return authorized ? (
    <>
      <Outlet />
      <SEOHelmet />
    </>
  ) : (
    <Navigate to={"/" + paths.signInPath} state={{ from: location }} replace />
  );
};

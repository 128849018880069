import { Typography } from "@mui/material";
import { FC } from "react";

export const BetaTag: FC = () => {
  return (
    <div className="bg-primary-0 bg-opacity-60 rounded-sm px-1 py-1 flex items-center justify-center">
      <Typography
        variant="caption2"
        className="text-primary-400 font-bold leading-none align-super"
      >
        Beta
      </Typography>
    </div>
  );
};

import { Close } from "@mui/icons-material";
import { Dialog, DialogProps, Typography } from "@mui/material";
import clsx from "clsx";
import React, { FC, HtmlHTMLAttributes } from "react";

interface ModalProps extends Omit<DialogProps, "title" | "children"> {
  open: boolean;
  onClose: () => void | Promise<void>;
  title?: string | JSX.Element;
  bodyProps?: HtmlHTMLAttributes<HTMLDivElement>;
  children?: React.ReactNode;
}

export const Modal: FC<ModalProps> = ({
  onClose,
  open,
  children,
  title,
  bodyProps = {},
  ...props
}) => (
  <Dialog
    PaperProps={{ className: "rounded-3xl" }}
    open={open}
    className="flex justify-center items-center"
    {...props}
    data-testid="dialog"
  >
    <>
      <div
        {...bodyProps}
        className={clsx(
          "max-w-[488px] bg-white p-6 rounded-3xl",
          bodyProps?.className
        )}
      >
        <div className="flex justify-between items-center mb-6">
          <Typography
            variant="h4"
            className="font-serif font-medium text-dark-text"
          >
            {title}
          </Typography>
          <div
            onClick={onClose}
            className="border-2 border-gray-200 w-[40px] h-[40px] rounded-full flex items-center justify-center cursor-pointer hover:border-gray-300 border-solid transition-colors text-primary-600 ml-4"
            data-testid="close-btn"
          >
            <Close />
          </div>
        </div>
        {children}
      </div>
    </>
  </Dialog>
);

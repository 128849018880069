import { isUndefined } from "lodash";
import projectService from "services/project.service";
import useSWR, { SWRConfiguration } from "swr";
import { IProjectUserPermissions } from "types/project.service";
import { useProjectAlias } from "./useProjectAlias";

export const useProjectPermissions = (
  config?: SWRConfiguration<IProjectUserPermissions>
) => {
  const alias = useProjectAlias();

  const { data: permissions, error } = useSWR(
    `/project/${alias}/permissions`,
    () => {
      return projectService.getProjectPermissions(alias);
    },
    config
  );

  const loading = [permissions, error].every(isUndefined);

  if (!permissions) {
    return {
      permissions: {
        canEdit: false,
        canView: false,
        canManage: false,
      },
      loading,
    };
  }

  return {
    permissions,
    loading,
  };
};

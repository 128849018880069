import { isBullpen } from "application/platformConfiguration";

const excludeItems =
  (condition: boolean) =>
  <T>(
    list: T[],
    key?: keyof T,
    fn = (item: T) =>
      key
        ? !list.some((listItem) => listItem[key] === item[key])
        : !list.includes(item)
  ) =>
  (item: T) =>
    condition ? true : fn(item);

export const excludeItemsWhenBullpen = excludeItems(!isBullpen());

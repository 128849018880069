import { lazy } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RememberMeCheck from "ui/Router/guards/RememberMeCheck/RememberMeCheck";
import * as paths from "ui/Router/paths";
import ScrollToTop from "ui/components/ScrollToTop/ScrollToTop";
import { useSyndicationLayout } from "ui/hooks";
import { CommonLayout, NotFound } from "ui/pages";
import { authRoutes } from "./routes/authRoutes";
import { mainTenantOnlyRoutes } from "./routes/mainTenantOnlyRoutes";
import { signupRoutes } from "./routes/signupRoutes";

const HelpCenter = lazy(() => import("../../pages/HelpCenter"));
const AsyncConfirmSignUp = lazy(
  () => import("ui/pages/ConfirmSignUp/ConfirmSignUp")
);
const AsyncLandingV2 = lazy(() => import("ui/pages/Landing/LandingV2"));
const AsyncSignIn = lazy(() => import("ui/pages/SignIn/SignIn"));
const AsyncSyndicateLogin = lazy(() => import("ui/pages/SyndicationLogin"));

const AsyncResetPassword = lazy(
  () => import("ui/pages/ResetPassword/ResetPassword")
);

const FederatedSignIn = lazy(
  () => import("ui/pages/FederatedSignIn/FederatedSignIn")
);
const AsyncPrivacyPolicy = lazy(
  () => import("ui/pages/PrivacyPolicy/PrivacyPolicy")
);
const AsyncTermsAndService = lazy(
  () => import("ui/pages/TermsAndService/TermsAndService")
);

interface EmergeRouterProps {
  hasLayoutError: boolean;
}

const EmergeRouter = ({ hasLayoutError }: EmergeRouterProps) => {
  const layout = useSyndicationLayout();
  const { isSyndicate } = layout;

  const favicon = layout.isSyndicate
    ? layout.mainInfo?.logo?.favicon ?? "/favicon.ico"
    : "/favicon.ico";

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Routes>
        <Route element={<RememberMeCheck />}>
          <Route
            path="/"
            element={<CommonLayout hasLayoutError={hasLayoutError} />}
          >
            {!isSyndicate && mainTenantOnlyRoutes}

            {signupRoutes}

            {authRoutes}

            <Route
              path={paths.oauthFederatedSignin}
              element={<FederatedSignIn />}
            />

            <Route
              index
              element={
                isSyndicate ? <AsyncSyndicateLogin /> : <AsyncLandingV2 />
              }
            />

            <Route
              path={paths.signInPath}
              element={isSyndicate ? <AsyncSyndicateLogin /> : <AsyncSignIn />}
            />

            <Route
              path={paths.resetPassword}
              element={<AsyncResetPassword />}
            />

            <Route path={paths.helpCenter} element={<HelpCenter />} />

            <Route
              path={paths.confirmSignUpPath}
              element={<AsyncConfirmSignUp />}
            />

            <Route
              path={paths.privacyPolicy}
              element={<AsyncPrivacyPolicy />}
            />

            <Route
              path={paths.termsOfService}
              element={<AsyncTermsAndService />}
            />

            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default EmergeRouter;

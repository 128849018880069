import * as paths from "ui/Router/paths";
import { FeatureFlagId } from "domain/featureFlag";
import { NavigationItemProps } from "ui/components";
import { isBullpen } from "application/platformConfiguration";
import { ReactComponent as IconFolderSVG } from "assets/svg/icon-folder.svg";
import { ReactComponent as userGroupSVG } from "assets/svg/icon-user-group.svg";

export const getBullpenNavItems = (
  isAuthorized: boolean
): NavigationItemProps[] =>
  isAuthorized ? authorizedBullpenNavItems : unathorizedBullpenNavItems;

export const authorizedBullpenNavItems: NavigationItemProps[] = [
  {
    title: "Home",
    hiddenForSyndicate: true,
    hiddenInLargerScreen: true,
  },
  {
    title: "Projects list",
    featureFlag: FeatureFlagId.Projects,
    restrictredByGroup: isBullpen() ? undefined : ["admin"],
    description:
      "Create and collaborate with other projects and work on various reports and files",
    to: "/" + paths.projectList,
    icon: IconFolderSVG,
  },
  {
    title: "Invite your contacts",
    description:
      "Share an invite to your contact to access your projects and collaborate on them",
    to: "/" + paths.inviteContacts,
    icon: userGroupSVG,
  },
  {
    title: "Settings",
    navItems: [
      {
        text: "Notifications",
        to: "/" + paths.notificationsPath,
      },
      {
        text: "Preferences",
        to: "/" + paths.preferencesPath,
      },
    ],
  },
];

export const unathorizedBullpenNavItems: NavigationItemProps[] = [
  {
    title: "Home",
    hiddenInLargerScreen: true,
    hiddenForSyndicate: true,
    navItems: [
      {
        text: "Product",
        to: paths.bullpenProduct,
      },
    ],
  },
];

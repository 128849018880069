import { lazy } from "react";
import { Route } from "react-router-dom";
import { AuthorizedOutlet } from "ui/Router/guards/AuthorizedOutlet";
import * as paths from "ui/Router/paths";
import { NotFound } from "ui/pages";
import { UserProfileWrapper } from "ui/pages/UserProfile/UserProfileWrapper/UserProfileWrapper";
import { RestrictedByGroupOutlet } from "ui/Router/guards/RestrictedByGroupOutlet";
import { CompanyPageLayout } from "ui/pages/CompanyPage/CompanyPageLayout";
import { CompanyPageWrapper } from "ui/pages/CompanyPage";
import { assistantManagementRoutes } from "./assistantManagementRoutes";
import { adminManagementRoutes } from "./adminManagementRoutes";
import { podcastRoutes } from "./podcastRoutes";
import { campaignRoutes } from "./campaignRoutes";
import { dealsRoutes } from "./dealsRoutes";
import { projectsRoutes } from "./projectsRoutes";

const FirstSteps = lazy(() => import("ui/pages/FirstSteps"));
const CompanyPeople = lazy(() => import("ui/pages/CompanyPage/CompanyPeople"));
const CompanyAbout = lazy(() => import("ui/pages/CompanyPage/CompanyAbout"));

const AuditActivities = lazy(() => import("ui/pages/AuditActivities"));

const Preferences = lazy(() => import("ui/pages/Preferences/Preferences"));

const InviteContacts = lazy(
  () => import("ui/pages/InviteContacts/InviteContacts")
);

const AsyncUserNotifications = lazy(
  () => import("ui/pages/UserNotifications/UserNotifications")
);
const AsyncUserProfile = lazy(() => import("ui/pages/UserProfile/UserProfile"));
const AsyncUserProfileEdit = lazy(
  () => import("ui/pages/UserProfileEdit/UserProfileEdit")
);

export const authRoutes = [
  <Route key="authRoutes" path="/" element={<AuthorizedOutlet />}>
    {assistantManagementRoutes}
    {adminManagementRoutes}
    {podcastRoutes}
    {campaignRoutes}
    {dealsRoutes}
    {projectsRoutes}

    <Route path={paths.firstSteps} element={<FirstSteps />} />

    <Route
      path={paths.auditActivities}
      element={<RestrictedByGroupOutlet admin />}
    >
      <Route index element={<AuditActivities />} />
    </Route>

    <Route path="company/:id" element={<CompanyPageLayout />}>
      <Route element={<CompanyPageWrapper />}>
        <Route path="people" element={<CompanyPeople />} />
        <Route path="about" element={<CompanyAbout />} />
      </Route>
    </Route>

    <Route
      path={paths.notificationsPath}
      element={<AsyncUserNotifications />}
    />

    <Route path={`${paths.preferencesPath}/*`} element={<Preferences />} />

    <Route path={paths.userProfile} element={<UserProfileWrapper />}>
      <Route index element={<AsyncUserProfile />} />
      <Route path="posts" element={<NotFound />} />
      <Route path="contacts" element={<NotFound />} />
      <Route path="stats" element={<NotFound />} />
      <Route path="edit" element={<AsyncUserProfileEdit />} />
    </Route>

    <Route path={paths.inviteContacts} element={<InviteContacts />} />
  </Route>,
];

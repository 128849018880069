import { FC } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, Link } from "react-router-dom";
import {
  signInPath,
  signUpPath,
  landingAi,
  landingISyndicate,
  landingInvest,
} from "ui/Router/paths";
import clsx from "clsx";
import { isBullpen } from "application/platformConfiguration";
import { BullpenButton } from "bullpen/components/BullpenButton";
import * as paths from "ui/Router/paths";

interface UnauthorizedBlockProps {
  light?: boolean;
}

const UnauthorizedBlock: FC<UnauthorizedBlockProps> = ({ light }) => {
  const { pathname } = useLocation();
  const signIn = pathname.startsWith("/" + signInPath);
  const signUp = pathname.startsWith("/" + signUpPath);
  const isLandingAI = pathname.startsWith("/" + landingAi);
  const isLandingInvest = pathname.startsWith("/" + landingInvest);
  const isLandingSyndicate = pathname.startsWith("/" + landingISyndicate);
  const isBullpenProductPage = pathname === `/${paths.bullpenProduct}`;

  const bullpenHomeButtonColor = light ? "tertiary" : "secondary";
  const bullpenLoginButtonColor =
    isBullpen() && isBullpenProductPage ? "secondary" : bullpenHomeButtonColor;

  return (
    <div className="h-full flex items-center -ml-3 md:ml-0">
      {(signIn || signUp) && (
        <Typography variant="caption" className="font-semibold hidden md:block">
          {signIn ? "Don't have an account?" : "Already have an account?"}
        </Typography>
      )}
      {!signUp &&
        (isBullpen() ? (
          <BullpenButton className="ml-3" href={"/" + signUpPath}>
            Sign Up
          </BullpenButton>
        ) : (
          <Link to={"/" + signUpPath}>
            <Button
              variant="contained"
              size="small"
              className={clsx("ml-3", {
                "bg-white hover:!bg-gray-200 active:!bg-gray-200 focus:!bg-gray-200 text-primary":
                  light,
                "text-dark-text": isLandingAI && light,
                "text-primary-aparawa": isLandingInvest && light,
                "text-danger-600": isLandingSyndicate && light,
              })}
            >
              Sign Up
            </Button>
          </Link>
        ))}
      {!signIn &&
        (isBullpen() ? (
          <BullpenButton
            variant={bullpenLoginButtonColor}
            href={"/" + signInPath}
            className="ml-3"
          >
            Login
          </BullpenButton>
        ) : (
          <Link to={"/" + signInPath}>
            <Button
              size="small"
              className={clsx("ml-3 border-2 border-solid", {
                "border-white hover:!bg-white active:!bg-white focus:!bg-white text-white hover:text-primary focus:text-primary active:text-primary":
                  light,
                "border-gray-200 hover:!bg-primary active:!bg-primary focus:!bg-primary text-primary hover:text-white focus:text-white active:text-white hover:border-primary focus:border-primary active:border-primary":
                  !light,
              })}
            >
              Login
            </Button>
          </Link>
        ))}
    </div>
  );
};

export default UnauthorizedBlock;

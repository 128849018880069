import { lazy } from "react";
import { Route } from "react-router-dom";
import { AuthorizedOutlet } from "ui/Router/guards/AuthorizedOutlet";
import { NotLoggedOutlet } from "ui/Router/guards/NotLoggedOutlet";
import * as paths from "ui/Router/paths";
import RegistrationWrapper from "ui/pages/Registration/RegistrationWrapper";

const AsyncRegistrationInvite = lazy(
  () => import("ui/pages/Registration/RegistrationInvite")
);
const AsyncRegistrationBasicInformation = lazy(
  () => import("ui/pages/Registration/RegistrationBasicInformation")
);
const AsyncRegistrationPhoneConfirmation = lazy(
  () => import("ui/pages/Registration/RegistrationPhoneConfirmation")
);
const AsyncRegistrationEmailConfirmation = lazy(
  () => import("ui/pages/Registration/RegistrationEmailConfirmation")
);
const AsyncRegistrationPosition = lazy(
  () => import("ui/pages/Registration/RegistrationPosition")
);
const AsyncRegistrationAccreditation = lazy(
  () => import("ui/pages/Registration/RegistrationAccreditation")
);
const AsyncRegistrationInterests = lazy(
  () => import("ui/pages/Registration/RegistrationInterests")
);
const AsyncRegistrationFollow = lazy(
  () => import("ui/pages/Registration/RegistrationFollow")
);
const AsyncRegistrationNDA = lazy(
  () => import("ui/pages/Registration/RegistrationNDA")
);
const AsyncRegistrationWelcome = lazy(
  () => import("ui/pages/Registration/RegistrationWelcome")
);

export const signupRoutes = [
  <Route
    key="signupRoutes"
    path={paths.signUpPath}
    element={<RegistrationWrapper />}
  >
    <Route element={<NotLoggedOutlet />}>
      <Route index element={<AsyncRegistrationBasicInformation />} />
      <Route path="invite" element={<AsyncRegistrationInvite />} />
    </Route>
    <Route
      path="phone-confirmation"
      element={<AsyncRegistrationPhoneConfirmation />}
    />
    <Route
      path="email-confirmation"
      element={<AsyncRegistrationEmailConfirmation />}
    />
    <Route element={<AuthorizedOutlet dismissRedirect />}>
      <Route path="position" element={<AsyncRegistrationPosition />} />
      <Route
        path="accreditation"
        element={<AsyncRegistrationAccreditation />}
      />
      <Route path="interests" element={<AsyncRegistrationInterests />} />
      <Route path="follow" element={<AsyncRegistrationFollow />} />
      <Route path="nda" element={<AsyncRegistrationNDA />} />
      <Route path="welcome" element={<AsyncRegistrationWelcome />} />
    </Route>
  </Route>,
];

import { Button, Typography } from "@mui/material";
import { clsxm } from "application/utils";
import { FC } from "react";
import { Link } from "react-router-dom";

export type SegmentControlButtonProps =
  | {
      title: string;
      isActive: boolean;
      tabIndex?: number;
      truncate?: boolean;
    } & (
      | { linkTo: string; onClick?: never }
      | { onClick: () => void; linkTo?: never }
    );

export const SegmentControlButton: FC<SegmentControlButtonProps> = ({
  isActive,
  title,
  linkTo,
  onClick,
  tabIndex,
  truncate,
}) => {
  const content = (
    <Button
      tabIndex={tabIndex}
      onClick={() => onClick?.()}
      className={clsxm(
        "bg-white z-10 h-9 uppercase w-full text-[12px] hover:text-primary-400 px-2",
        isActive
          ? "text-primary-400 hover:bg-white/60"
          : "text-gray-400 bg-transparent"
      )}
    >
      <Typography
        variant="hairline2"
        className={clsxm("px-2", truncate && "truncate")}
      >
        {title}
      </Typography>
    </Button>
  );

  if (linkTo) {
    return (
      <Link className="w-full" to={linkTo}>
        {content}
      </Link>
    );
  }

  return content;
};

import clsx from "clsx";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { useGroupValidation, useSyndicationLayout } from "ui/hooks";
import { FeatureFlagId } from "domain/featureFlag";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";
import { RolesArray } from "types";
import { RestrictedByRegistrationStepsProps } from "ui/Router/guards/RestrictedByRegistrationSteps";
import ChevronRightRounded from "@mui/icons-material/ChevronRightRounded";
import styles from "./NavigationItem.module.scss";
import { isEmerge9 } from "application/platformConfiguration";

interface INavigationItem {
  text: string;
  to?: string;
  external?: boolean;
}

export interface NavigationItemProps {
  title: string;
  description?: string;
  icon?: any;
  to?: string;
  navItems?: INavigationItem[];
  footer?: boolean;
  hiddenInLargerScreen?: boolean;
  hiddenForSyndicate?: boolean;
  featureFlag?: FeatureFlagId;
  restrictredByGroup?: RolesArray;
  restrictredByRegistrationSteps?: RestrictedByRegistrationStepsProps;
}

export const NavigationItem = ({
  title,
  description,
  icon: Icon,
  to,
  navItems,
  footer,
  hiddenInLargerScreen,
  hiddenForSyndicate,
  featureFlag,
  restrictredByGroup,
}: NavigationItemProps) => {
  const theme = useTheme();
  const breakpointMdOrMore = useMediaQuery(theme.breakpoints.up("md"));
  const { isSyndicate } = useSyndicationLayout();
  const { isFlagEnabled } = useFeatureFlag();
  const { hasRole, isDeveloper } = useGroupValidation();

  if (featureFlag === FeatureFlagId.Projects && isEmerge9() && !isDeveloper) {
    return null;
  }

  if (hiddenInLargerScreen && breakpointMdOrMore) {
    return null;
  }

  if (hiddenForSyndicate && isSyndicate) {
    return null;
  }

  if (featureFlag && !isFlagEnabled(featureFlag)) {
    return null;
  }

  if (restrictredByGroup && !hasRole(restrictredByGroup)) {
    return null;
  }

  if (to && (!navItems || navItems?.length === 0)) {
    return (
      <Link to={to}>
        <div
          className={clsx(
            "group hover:bg-primary-light",
            styles.navigationPrimaryItem
          )}
        >
          <div className="flex items-center">
            {Icon && <Icon className="mr-1 w-[16px]" />}
            <Typography
              component="p"
              className={clsx(
                "font-semibold text-[14px] text-gray-400 group-hover:text-primary-400",
                {
                  "text-gray-100 pb-10": footer,
                }
              )}
            >
              {title}
            </Typography>
            <ChevronRightRounded
              className="text-gray-400 group-hover:text-primary-400 ml-2"
              fontSize="inherit"
            />
          </div>
          <Typography className="text-[12px] mt-1 text-gray-400 group-hover:text-primary-400">
            {description}
          </Typography>
        </div>
      </Link>
    );
  }

  return (
    <div>
      <Typography
        component="p"
        variant="body2"
        className={clsx("font-semibold text-primary-400 pb-6", {
          "text-gray-100 pb-10": footer,
        })}
      >
        {title}
      </Typography>
      {Boolean(navItems?.length) && (
        <div
          className={clsx("space-y-4 flex flex-col ml-2", {
            "space-y-6": footer,
          })}
        >
          {navItems?.map(({ text, to = "#", external }, index) => {
            const content = (
              <Typography
                component="p"
                variant="button2"
                className="font-semibold text-gray-400 hover:text-primary-400"
              >
                {text}
              </Typography>
            );

            if (external) {
              return (
                <a
                  key={index}
                  href={to}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {content}
                </a>
              );
            }

            return (
              <Link key={index} to={to}>
                {content}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NavigationItem;

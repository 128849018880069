import * as React from "react";
import type { SVGProps } from "react";
const SvgResearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#CCB502"
      fillRule="evenodd"
      d="M9.333 1.333A5.333 5.333 0 0 0 4 6.667v18.666a5.333 5.333 0 0 0 5.333 5.334H20.91A8.04 8.04 0 0 1 18.404 28h-9.07a2.667 2.667 0 0 1-2.667-2.667V6.667A2.667 2.667 0 0 1 9.333 4h9.334v2.667a4 4 0 0 0 4 4h2.666V16c.935 0 1.833.16 2.667.455v-7.58a5.33 5.33 0 0 0-1.562-3.77l-2.21-2.21c-1-1-2.356-1.562-3.77-1.562zm15.22 5.657c.287.287.502.633.632 1.01h-2.518a1.333 1.333 0 0 1-1.334-1.333v-2.52c.377.132.723.347 1.01.634z"
      clipRule="evenodd"
    />
    <path
      fill="#CCB502"
      d="M16 14.667c-.736 0-1.333.597-1.333 1.333v6.667a1.333 1.333 0 1 0 2.666 0V16c0-.736-.597-1.333-1.333-1.333M9.333 20A1.333 1.333 0 0 1 12 20v2.667a1.333 1.333 0 0 1-2.667 0zM30.99 29.657c.52-.521.52-1.365 0-1.886l-1.062-1.062a5.333 5.333 0 1 0-1.886 1.886l1.063 1.062c.52.52 1.364.52 1.885 0M28 24a2.667 2.667 0 1 1-5.333 0A2.667 2.667 0 0 1 28 24"
    />
  </svg>
);
export default SvgResearch;

import { Chip, useTheme } from "@mui/material";
import clsx from "clsx";
import { FC, useMemo } from "react";

export enum StatusBadgeType {
  Success = "Success",
  Error = "Error",
  Draft = "Draft",
  Default = "Default",
  Info = "Info",
}

export interface StatusBadgeProps {
  type?: StatusBadgeType;
  label: string;
  className?: string;
}

export const StatusBadge: FC<StatusBadgeProps> = ({
  type = StatusBadgeType.Default,
  className,
  label,
}) => {
  const theme = useTheme();

  const { colorProps, bgColor } = useMemo(() => {
    switch (type) {
      case StatusBadgeType.Draft:
        return {
          colorProps: "bg-orange-700 hover:bg-orange-800",
          bgColor: theme.customColors.semantic.orange700,
        };
      case StatusBadgeType.Success:
        return {
          colorProps: "bg-primary-200 hover:bg-primary-300",
          bgColor: theme.customColors.primary[200],
        };
      case StatusBadgeType.Error:
        return {
          colorProps: "bg-error hover:bg-danger-300",
          bgColor: theme.palette.error.main,
        };
      case StatusBadgeType.Info:
        return {
          colorProps: "bg-complementary-300 hover:bg-complementary-400",
          bgColor: theme.customColors.complementary[300],
        };
      case StatusBadgeType.Default:
      default:
        return {
          colorProps: "bg-gray-300 hover:bg-gray-400",
          bgColor: theme.customColors.gray[300],
        };
    }
  }, [type, theme]);

  return (
    <Chip
      label={label}
      className={clsx(colorProps, className)}
      sx={{
        color:
          theme.palette.getContrastText(bgColor) === "#fff"
            ? theme.customColors.gray[100]
            : theme.customColors.text.dark,
      }}
    />
  );
};

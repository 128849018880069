import * as React from "react";
import type { SVGProps } from "react";
const SvgAlpinvest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={135}
    height={29}
    fill="#fff"
    {...props}
  >
    <g fillRule="evenodd" clipPath="url(#alpinvest_svg__a)" clipRule="evenodd">
      <path d="m18.555 10.332-1.501.232-.327.058-1.41.278c-.235.045-.888.194-1.521.342l1.958-4.727c.268-.613.53-1.433.692-1.937.163.51.425 1.33.692 1.937l1.528 3.817zm7.56-.691c-.136 0-.587 0-.92.039h-.45l-.797.064-.731.052-1.064.09-.3.039-.96.13L16.629 0h-.418l-5.02 11.92-.32.097-.908.284-1.678.614-.588.252a20 20 0 0 0-4.478 2.582c-2.886 2.222-3.22 4.663-3.22 5.612a4.83 4.83 0 0 0 .79 2.731c1.529 2.493 5.355 4.353 9.99 4.85.294 0 .777.058 1.117.058h.293l.745-1.485h-.444a18 18 0 0 1-3.22-.394c-4.524-.962-7.834-3.487-7.9-6.005-.032-1.847.314-4.043 4.917-6.703q.886-.508 1.835-.891c.294-.071 1.795-.704 1.795-.704.196-.084.601-.22 1.058-.368L7.41 20.89H9.84l3.76-9.176c.32-.071.654-.155.771-.194.124-.052 1.587-.362 2.612-.543l1.821-.296 4.185 10.209h2.475L21.01 10.396l1.123-.122.418-.033.653-.051.953-.071.823-.071h.065l.914-.052h.575c.32 0 1.37 0 2.357-.045h.757l.183-.368c-.953 0-3.395.064-3.722.09M43.679 8.395a2.5 2.5 0 0 1-.222-.601q-.105.31-.255.6l-1.834 3.946h4.158zm3.917 8.394-1.168-2.486H40.48l-1.162 2.486h-2.43l6.588-13.225 6.549 13.225zM52.264 16.77V4.088h2.168v10.738h5.38v1.944zM65.597 10.48c1.88 0 2.833-.994 2.833-2.35s-.868-2.08-2.487-2.08h-1.228v4.43zm-3.056 6.31V4.088h2.801c3.036 0 5.38.988 5.38 4.055 0 2.55-1.613 4.281-5.073 4.281h-.934v4.366zM73.993 16.79h1.22V4.088h-1.22zM80.829 6.87l.02 9.92h-1.222V3.764l9.84 10.37V4.088h1.22l-.013 13.134zM93.3 4.088h1.442l3.99 9.796q.09.296.097.607.015-.31.105-.607l3.989-9.796h1.423l-5.55 13.238zM106.958 16.77V4.1h6.666V5.18h-5.445v4.262h4.381v1.085h-4.381v5.172h5.987v1.072zM116.934 14.47c.403.465.904.835 1.469 1.085.564.25 1.177.374 1.796.362 1.527 0 2.663-.91 2.663-2.337 0-3.604-5.934-2.764-5.934-6.542 0-2.098 1.775-3.228 3.662-3.228 1.183 0 2.317.465 3.154 1.291l-.653.852a3.39 3.39 0 0 0-2.461-1.04c-1.221 0-2.41.647-2.41 2.015 0 3.035 5.974 2.131 5.974 6.645 0 1.724-1.475 3.455-4.074 3.455a5.3 5.3 0 0 1-2.139-.428 5.3 5.3 0 0 1-1.778-1.251zM130.051 16.79V5.178h-3.735V4.101H135v1.078h-3.728v11.61z" />
    </g>
    <defs>
      <clipPath id="alpinvest_svg__a">
        <path d="M0 0h135v29H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAlpinvest;

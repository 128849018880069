import * as paths from "ui/Router/paths";
import { useFeatureFlag } from "./useFeatureFlag";
import { isBullpen } from "application/platformConfiguration";

export const usePaths = () => {
  const { isProjectsOnly } = useFeatureFlag();

  const redirect = isProjectsOnly ? paths.projectList : paths.dealsAll;

  return {
    mainRedirectPath: `/${isBullpen() ? paths.projectList : redirect}`,
  };
};

import projectReportService from "services/projectReport.service";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { IProjectReportModule } from "types/project.service";

export const getProjectReportModuleSWRKey = (
  projectId: string,
  reportId: string,
  moduleId: string
) =>
  `/Project/${projectId}/report-builder/reports/${reportId}/modules/${moduleId}`;

export const useProjectReportModule = (
  projectId: string,
  reportId?: string,
  moduleId?: string,
  config?: SWRConfiguration<IProjectReportModule>
): SWRResponse<IProjectReportModule> => {
  return useSWR(
    reportId &&
      moduleId &&
      getProjectReportModuleSWRKey(projectId, reportId, moduleId),
    () => projectReportService.getReportModule(projectId, reportId!, moduleId!),
    config
  );
};

import { Typography } from "@mui/material";
import { cleanAssistantMessage, getRichTextContent } from "application/utils";
import clsx from "clsx";
import { AssistantThreadMessageRole } from "domain/assistant";
import { FC } from "react";
import {
  AssistantMessageContent,
  AssistantMessageHeader,
  AssistantMessageHeaderProps,
  MessageLoader,
} from "ui/components/AssistantChat/AssistantChat.components";
import { IAssistantChatMessage } from "ui/components/AssistantFloatingChat";
import { MarkdownInputViewer } from "ui/components/MarkdownInput";
import { useUserProfile } from "ui/hooks";
import { CopyButton } from "../Button";

type ChatSideBarMessageProps =
  | {
      message: IAssistantChatMessage;
      loading?: false;
    }
  | {
      loading: true;
      message?: never;
    };

export const AssistantFloatingChatMessage: FC<ChatSideBarMessageProps> = ({
  message,
  loading,
}) => {
  const { data: profile } = useUserProfile();
  const isUserMessage =
    !loading && message.role !== AssistantThreadMessageRole.Assistant;

  const content = loading ? (
    <div className="p-3">
      <MessageLoader />
    </div>
  ) : (
    <div className="flex">
      <Typography variant="body2" className={clsx("rounded-lg grow w-fit")}>
        {isUserMessage ? (
          message.content
        ) : (
          <MarkdownInputViewer>
            {getRichTextContent(
              cleanAssistantMessage(message.content),
              "markdown"
            )}
          </MarkdownInputViewer>
        )}
      </Typography>
      {message.textToCopy && (
        <div className="h-full pl-2 grow-0 shrink-0">
          <CopyButton
            textToCopy={message.textToCopy}
            className="sticky top-1"
          />
        </div>
      )}
    </div>
  );

  const headerProps: AssistantMessageHeaderProps = isUserMessage
    ? {
        isUserMessage,
        userInformation: {
          fullName: profile?.fullName || "",
          imageUrl: profile?.imageUrl || "",
        },
      }
    : {};

  return (
    <div className="my-4 w-full">
      <AssistantMessageHeader {...headerProps} />
      <AssistantMessageContent isUserMessage={isUserMessage}>
        {content}
      </AssistantMessageContent>
    </div>
  );
};

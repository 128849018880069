import { platformConfiguration } from "application/platformConfiguration";

export const DEFAULT_PAGE = {
  title: platformConfiguration.displayName,
  description: platformConfiguration.secondaryDescription,
};

export const SIGN_IN_PAGE = {
  title: "Sign in",
  description: `Sign in. ${platformConfiguration.secondaryDescription}`,
};

export const SIGN_UP_PAGE = {
  title: "Sign up",
  description: `Sign up. ${platformConfiguration.secondaryDescription}`,
};

export const SIGN_UP_EMAIL_CONFIRMATION_PAGE = {
  title: "Sign up - Email Confirmation",
  description: `Sign up - EmailConfirmation. ${platformConfiguration.secondaryDescription}`,
};

export const LANDING_PAGE = {
  title: platformConfiguration.landingTitle,
  description: platformConfiguration.landingDescription,
};

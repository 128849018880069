import { Outlet } from "react-router-dom";
import { IProjectUserPermissions } from "types/project.service";
import { useProjectPermissions } from "ui/hooks";
import { NotFound } from "ui/pages";

export const ProjectGuard = ({
  checkPermission,
}: {
  checkPermission: (permissions: IProjectUserPermissions) => boolean;
}) => {
  const { permissions } = useProjectPermissions();

  if (!checkPermission(permissions)) {
    return <NotFound />;
  }

  return <Outlet />;
};

import React from "react";
import Typography from "@mui/material/Typography";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { DealBiographerCard } from "ui/components";
import { useDealKeyBiographies, useDealPaths } from "ui/hooks";
import { DetailSection } from "../DetailSection/DetailSection";

const DealKeyBiographies = () => {
  const { editDealKeyBiographiesPath: editPath } = useDealPaths();
  const { data } = useDealKeyBiographies();

  const { description, keyBiographers: items } = data || {};

  return (
    <DetailSection
      title="Key Biographies"
      editRoute={editPath}
      isEmpty={!data}
      className="pb-4"
    >
      {description && (
        <Typography variant="body2" className="whitespace-pre-line block pb-4">
          {description}
        </Typography>
      )}
      {Array.isArray(items) && (
        <div>
          <Swiper
            modules={[Pagination]}
            spaceBetween={30}
            breakpoints={{
              "320": {
                slidesPerView: 1,
              },
              "1024": {
                slidesPerView: 2,
              },
            }}
            pagination={{ clickable: true }}
          >
            {items.map((itemData, index) => (
              <SwiperSlide key={index} className="sm:pr-[1px] box-border">
                <DealBiographerCard
                  {...itemData}
                  className="box-border mb-[30px] !w-full min-w-[200px]"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </DetailSection>
  );
};

export default DealKeyBiographies;

import Stack from "@mui/material/Stack";
import * as paths from "ui/Router/paths";
import {
  Item,
  NavigationItemsProps,
} from "ui/pages/CommonLayout/Header/HeaderNav/HeaderNav";

export const BullpenHeaderNavigationItems = ({
  light,
  isAuthorized,
}: Omit<NavigationItemsProps, "isSyndicate">) => {
  if (isAuthorized) {
    return null;
  }

  return (
    <Stack direction="row" spacing={4} className="flex items-center">
      <Item to={paths.bullpenProduct} label="Product" light={light} />
    </Stack>
  );
};

import { Check, CopyAll } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { EditNavButton } from "ui/components";
import { useDealPermissions } from "ui/hooks";

interface Props {
  title: string;
  to?: string;
  onCopy?: () => void | Promise<void>;
}

const DealSectionTitle = ({ title, to, onCopy }: Props) => {
  const { permissions } = useDealPermissions();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <div className="flex items-center min-h-[32px] pb-3">
      <Typography
        component="h3"
        variant="hairline2"
        className="text-gray-300 block uppercase"
      >
        {title}
      </Typography>
      <div className="grow" />
      <div className="flex space-x-2">
        {permissions?.edit && onCopy && (
          <Tooltip title={copied ? "Copied" : "Copy"}>
            <IconButton
              className="h-[32px] w-[32px] text-primary border-2 border-solid border-gray-200 text-[12px]"
              onClick={async () => {
                await onCopy();
                setCopied(true);
              }}
            >
              {copied ? (
                <Check className="text-[16px]" />
              ) : (
                <CopyAll className="text-[16px]" />
              )}
            </IconButton>
          </Tooltip>
        )}
        {permissions?.edit && to && <EditNavButton to={to} />}
      </div>
    </div>
  );
};

export default DealSectionTitle;

import {
  Autocomplete,
  FilterOptionsState,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  UseAutocompleteProps,
} from "@mui/material";
import { clsxm, filterOptions } from "application/utils";
import { ReactComponent as ArrowDropDownIcon } from "assets/svg/arrow-drop-down-icon.svg";
import { ReactComponent as IconCircleClose } from "assets/svg/icon-circle-close.svg";
import { defaultsDeep, isEmpty, omit } from "lodash";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

interface AutocompleteControllerProps<
  T extends FieldValues,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends UseControllerProps<T>,
    Omit<
      UseAutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>,
      "defaultValue" | "options"
    > {
  showError?: boolean;
  label?: string | null;
  loading?: boolean;
  options?: Option[];
  placeholder?: string;
  getValue?: (value: any) => any;
  hideClearIcon?: boolean;
  small?: boolean;
  customOnChange?: (value: any) => void;
  className?: string;
  textFieldClassName?: string;
  hideTooltip?: boolean;
  customFilterOptions?: Option[];
}

const autocompletefilterOptions = (
  options: Option[],
  state: FilterOptionsState<Option>
): Option[] => filterOptions(options, state.inputValue);

export const AutocompleteController = <
  T extends FieldValues,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  control,
  loading,
  label,
  options = [],
  placeholder = "",
  showError,
  hideClearIcon,
  getValue = (v) => v,
  small,
  customOnChange,
  className,
  textFieldClassName,
  hideTooltip = false,
  customFilterOptions,
  ...props
}: AutocompleteControllerProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const fieldValue =
    field.value && "value" in field.value ? field.value.value : field.value;

  return (
    <div className={clsxm(className)}>
      {label === null ? null : (
        <InputLabel className="pb-3">{label}</InputLabel>
      )}
      <Autocomplete
        onChange={(_, data) => {
          field.onChange(getValue(data));
          customOnChange?.(data);
        }}
        loading={loading}
        value={getValue(field.value)}
        options={options}
        filterOptions={
          customFilterOptions
            ? () => customFilterOptions
            : autocompletefilterOptions
        }
        getOptionLabel={(option) => (option as Option)?.label ?? ""}
        isOptionEqualToValue={(option, value) =>
          value === undefined || option.value === value.value
        }
        classes={{
          tag: "text-gray-100",
          endAdornment: clsxm("top-[calc(50%-18px)]", {
            "top-[calc(50%-20px)]":
              props.multiple && (field?.value as any[])?.length === 1,
          }),
          clearIndicator: "p-0.5",
          listbox: "p-2 space-y-2",
          option: "p-[10px] h-[40px] rounded-lg",
          input: small ? "!p-1" : "p-[7px_4px_7px_6px] h-4",
          popper: clsxm(customFilterOptions?.length === 0 && "hidden"),
        }}
        clearIcon={
          !hideClearIcon && (
            <IconCircleClose width="32" height="32" viewBox="0 0 40 40" />
          )
        }
        popupIcon={
          <ArrowDropDownIcon width="32" height="32" viewBox="0 0 32 32" />
        }
        renderInput={(params) => (
          <TextField
            error={showError && Boolean(fieldState?.error)}
            {...defaultsDeep(params, {
              InputProps: {
                notched: false,
                placeholder: isEmpty(fieldValue) ? placeholder : "",
                style: { paddingRight: 68, padding: 8.5 },
              },
            })}
            variant="outlined"
            fullWidth
            className={textFieldClassName}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const label = option.label || "No name";

          const escapedString = inputValue.replace(
            /[-/\\^$*+?.()|[\]{}]/g,
            "\\$&"
          );
          const regex = new RegExp(`(${escapedString})`, "ig");
          const htmlContent = label.replace(regex, "<strong>$1</strong>");
          const propsWithoutKey = omit(props, ["key"]);
          const listItem = (
            <li
              {...propsWithoutKey}
              key={hideTooltip ? option.value : undefined}
            >
              <span
                className="whitespace-nowrap overflow-hidden text-ellipsis py-2"
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              />
            </li>
          );

          return hideTooltip ? (
            listItem
          ) : (
            <Tooltip
              disableInteractive
              placement="top-start"
              title={<>{label}</>}
              key={option.value}
            >
              {listItem}
            </Tooltip>
          );
        }}
        {...props}
      />
      {showError && fieldState?.error && (
        <Typography
          variant="caption2"
          className="font-semibold text-error pt-2"
        >
          {fieldState.error.message}
        </Typography>
      )}
    </div>
  );
};

import { platformConfiguration } from "application/platformConfiguration";
import { DEFAULT_PLATFORM_ID } from "domain/common";
import { useMemo } from "react";
import { ISyndicationPartnerLayout } from "types/syndication.service";
import useTypedSelector from "../useTypeSelector";

export type IUseSyndicateLayout =
  | {
      isSyndicate: false;
      platformShortName: string;
      platformLegalName: string;
      isCurrentPartner: (partnerIdOrAlias?: string) => boolean;
    }
  | ({
      isSyndicate: true;
      platformShortName: string;
      platformLegalName: string;
      isCurrentPartner: (partnerIdOrAlias?: string) => boolean;
    } & ISyndicationPartnerLayout);

export const useSyndicationLayout = (): IUseSyndicateLayout => {
  const syndicateLayout = useTypedSelector(
    ({ syndicate }) => syndicate.syndicateLayout
  );

  const data: IUseSyndicateLayout = useMemo(() => {
    if (!syndicateLayout) {
      return {
        isSyndicate: false,
        platformShortName: platformConfiguration.displayName,
        platformLegalName: platformConfiguration.legalName,
        isCurrentPartner: (partnerIdOrAlias?: string) =>
          !partnerIdOrAlias || partnerIdOrAlias === DEFAULT_PLATFORM_ID,
      };
    }

    return {
      isSyndicate: true,
      isCurrentPartner: (partnerIdOrAlias?: string) =>
        partnerIdOrAlias === syndicateLayout.mainInfo.alias ||
        partnerIdOrAlias === syndicateLayout.mainInfo.id,
      platformShortName: syndicateLayout.mainInfo.shortName,
      platformLegalName: syndicateLayout.mainInfo.legalName,
      ...syndicateLayout,
    };
  }, [syndicateLayout]);

  return data;
};

import * as paths from "ui/Router/paths";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showDisclosureModal } from "redux/UI/reducer";
import { useSyndicationLayout, useTypedSelector } from "ui/hooks";
import { getCurrentYear } from "application/utils";
import { Collapse } from "@mui/material";
import { platformConfiguration } from "application/platformConfiguration";
import { ShowOnEmerge9 } from "ui/components/PlatformSpecificElement";

interface Props {
  hide: boolean;
}

export const Footer = ({ hide }: Props) => {
  const syndicationLayout = useSyndicationLayout();
  const dispatch = useDispatch();
  const { isContentFullScreen } = useTypedSelector(({ UI }) => UI);

  const openDisclosure = () => {
    dispatch(showDisclosureModal());
  };

  if (hide) {
    return null;
  }

  return (
    <Collapse in={!isContentFullScreen} className="w-full bg-white z-20">
      <Divider />
      <div className="m9-container flex flex-col sm:flex-row items-center !py-4 !px-0">
        <Typography variant="caption2">
          Copyright © {getCurrentYear()}{" "}
          {syndicationLayout.isSyndicate
            ? syndicationLayout.mainInfo.shortName
            : platformConfiguration.copyrightName}
          . All rights reserved
        </Typography>
        <div className="grow" />
        <ShowOnEmerge9>
          <div className="flex flex-col sm:flex-row items-center">
            <Typography
              variant="caption2"
              className="text-gray-400 inline-flex whitespace-pre"
            >
              See{" "}
              {syndicationLayout.isSyndicate
                ? syndicationLayout.mainInfo.shortName
                : "Emerge9"}{" "}
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  openDisclosure();
                }}
                to="#"
              >
                Disclosure
              </Link>
            </Typography>
            <Divider
              orientation="vertical"
              className="hidden sm:inline-block mx-2"
              flexItem
            />
            <span>
              <Typography
                variant="caption2"
                className="text-gray-400 inline-flex whitespace-pre"
              >
                <Link to={"/" + paths.privacyPolicy}>Privacy Policy</Link>
                {" and "}
              </Typography>
              <Typography
                variant="caption2"
                className="text-gray-400 inline-flex whitespace-pre"
              >
                <Link to={"/" + paths.termsOfService}>Terms of Service</Link>
                {" apply."}
              </Typography>
            </span>
          </div>
        </ShowOnEmerge9>
      </div>
    </Collapse>
  );
};

export default Footer;

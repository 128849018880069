import { AxiosRequestConfig } from "axios";
import {
  IAssistantAllowedRecipient,
  IAssistantThread,
  ICreateAllowedRecipient,
  IDetailedThread,
  ITemplateAttachedField,
} from "types/assistant.service";
import { ResourceType } from "types/resource.service";
import { apiClient, fetchWithAuth } from "./clients";
import { ITextRange } from "domain/common";

export abstract class AssistantResourceService {
  abstract readonly resourceType: ResourceType;

  private readonly basePath = (resourceId: string) =>
    `${this.resourceType}/${resourceId}/assistant`;

  async getFields(resourceId: string): Promise<ITemplateAttachedField[]> {
    const { data } = await apiClient.get(`${this.basePath(resourceId)}/fields`);

    return data;
  }

  async getThreads(resourceId: string): Promise<IAssistantThread[]> {
    const { data } = await apiClient.get(
      `${this.basePath(resourceId)}/threads`
    );

    return data;
  }

  async getThreadById(
    resourceId: string,
    threadId: string
  ): Promise<IDetailedThread> {
    const { data } = await apiClient.get(
      `${this.basePath(resourceId)}/threads/${threadId}`
    );

    return data;
  }

  async createFieldThread(
    resourceId: string,
    fieldId: string
  ): Promise<IAssistantThread> {
    const { data } = await apiClient.post(
      `${this.basePath(resourceId)}/fields/${fieldId}/threads`
    );

    return data;
  }

  async createAndRunFieldThread(
    resourceId: string,
    fieldId: string
  ): Promise<IAssistantThread> {
    const { data } = await apiClient.post(
      `${this.basePath(resourceId)}/fields/${fieldId}/threads/run`
    );

    return data;
  }

  async populateFieldThread(
    resourceId: string,
    threadId: string,
    config?: RequestInit
  ): Promise<ReadableStream> {
    const response = await fetchWithAuth(
      `${this.basePath(resourceId)}/threads/${threadId}/field/populate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        ...config,
      }
    );

    if (!response.body || !response.body.pipeTo) {
      throw new Error("ReadableStream not supported!");
    }

    return response.body;
  }

  async createThread(resourceId: string, name: string | null) {
    const { data } = await apiClient.post(
      `${this.basePath(resourceId)}/threads`,
      {
        name,
      }
    );

    return data;
  }

  async updateThread(resourceId: string, threadId: string, name: string) {
    const { data } = await apiClient.put(
      `${this.basePath(resourceId)}/threads/${threadId}`,
      {
        name,
      }
    );

    return data;
  }

  async deleteThread(resourceId: string, threadId: string): Promise<void> {
    await apiClient.delete(`${this.basePath(resourceId)}/threads/${threadId}`);
  }

  async addThreadMessage(
    resourceId: string,
    threadId: string,
    content: string,
    selection?: ITextRange
  ): Promise<ReadableStream> {
    const response = await fetchWithAuth(
      `${this.basePath(resourceId)}/threads/${threadId}/messages`,
      {
        method: "POST",
        body: JSON.stringify({
          Role: "user",
          content,
          selection,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.body || !response.body.pipeTo) {
      throw new Error("ReadableStream not supported!");
    }

    return response.body as ReadableStream;
  }

  async toggleDealAssistant(
    resourceId: string,
    isEnabled: boolean
  ): Promise<void> {
    await apiClient.put(`${this.basePath(resourceId)}/enable`, {
      isEnabled,
    });
  }

  async getAllowedRecipients(
    resourceId: string,
    config?: AxiosRequestConfig
  ): Promise<PaginatedTokenResponse<IAssistantAllowedRecipient>> {
    const { data } = await apiClient.get(
      `${this.basePath(resourceId)}/allowed-recipients`,
      config
    );

    return data;
  }

  async deleteAllowedRecipient(
    resourceId: string,
    email: string
  ): Promise<IAssistantAllowedRecipient[]> {
    const { data } = await apiClient.delete(
      `${this.basePath(resourceId)}/allowed-recipients`,
      { params: { email } }
    );

    return data;
  }

  async addAllowedRecipients(
    resourceId: string,
    payload: ICreateAllowedRecipient[]
  ): Promise<IAssistantAllowedRecipient[]> {
    const { data } = await apiClient.post(
      `${this.basePath(resourceId)}/allowed-recipients`,
      payload
    );

    return data;
  }

  async uploadCSVAllowedRecipients(
    resourceId: string,
    formData: FormData,
    config?: AxiosRequestConfig
  ) {
    const { data } = await apiClient.post(
      `${this.basePath(resourceId)}/allowed-recipients/csv`,
      formData,
      config
    );
    return data;
  }
}

import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { baseApi } from "./Api/BaseApi";
import { persistConfig } from "./persistConfig";

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [baseApi.middleware, thunk];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  devTools: process.env.NODE_ENV === "development",
});

export const persistor = persistStore(store);

export default store;

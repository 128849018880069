import { PlatformProjectName } from "domain/platform";
import { FC, ReactNode } from "react";
import { PlatformSpecificElement } from "./PlatformSpecificElement";

interface PlatformElementProps {
  children: ReactNode;
}

export const ShowOnBullpen: FC<PlatformElementProps> = ({ children }) => {
  return (
    <PlatformSpecificElement platform={PlatformProjectName.Bullpen}>
      {children}
    </PlatformSpecificElement>
  );
};

export const ShowOnEmerge9: FC<PlatformElementProps> = ({ children }) => {
  return (
    <PlatformSpecificElement platform={PlatformProjectName.Emerge9}>
      {children}
    </PlatformSpecificElement>
  );
};
